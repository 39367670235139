import { Component, OnInit } from '@angular/core';
import { NewsService } from '../news.service';
import { generalHelper } from '../../../helpers/generalHelper';
import {Router, ActivatedRoute} from '@angular/router';
import { imagePathHelper } from '../../../helpers/imagePathHelper';
import {ConfirmationService} from 'primeng/api';
import Swal from "sweetalert2";
import {HttpEventType} from "@angular/common/http";
import { AuthService } from 'app/services/auth.service';
import { environment } from 'enviornments/environment';
@Component({
    selector: 'app-view',
    templateUrl: './view.component.html',
    styleUrls: ['./view.component.scss']
})


export class ViewComponent implements OnInit {
    env=environment;
    user :any;
    generalHelper = generalHelper;
    imageHelper : any;
    imageThumb : any;
    token: any;
    newsPatch:any=[];
    PatchThumbnail:any
    PatchImage1:any
    PatchImage2:any
    PatchImage3:any
    PatchImage4:any
    PatchImage5:any
    loading: boolean;
    errors: boolean;
    uploadStatus: number;
    zoomimage:boolean=false;
    showingimage:any='';
    constructor(private NewsService: NewsService,private router: Router, private route: ActivatedRoute,private confirmationService: ConfirmationService, private authService: AuthService) { }

    ngOnInit() {
        this.user = this.authService.getLoggedInUser();
        this.token = this.route.snapshot.params.id;
        this.getView();
    }
    
    getView() {
        const ID  = this.token;
         this.NewsService.Edit(ID).then((res) => {
         this.newsPatch = res.data;
         this.PatchThumbnail= res.data.thumbnail.replace('public',this.env.imageURL);
         if(res.data.image1) this.PatchImage1 = res.data.image1.replace('public',this.env.imageURL);
         if(res.data.image2) this.PatchImage2 = res.data.image2.replace('public',this.env.imageURL);
         if(res.data.image3) this.PatchImage3 = res.data.image3.replace('public',this.env.imageURL);
         if(res.data.image4) this.PatchImage4 = res.data.image4.replace('public',this.env.imageURL);
         if(res.data.image5) this.PatchImage5 = res.data.image5.replace('public',this.env.imageURL);
     });
}

imageZomming(image){
    this.zoomimage=true;
    this.showingimage=image;

}

editNews(){
    this.router.navigate(['solo/news/edit/'+ this.token]);
}

async adminApproval(){
    const params = {
        'status':'Pending',
        'id': this.token,
    }

    Swal.fire({
        icon: 'info',
        title: 'Send Request',
        showCancelButton: true,
        html: 'Do you want to send this news for approval to admin ?',
        confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
    }).then((result) => {
        if (result.isConfirmed) {
            this.NewsService.adminApproval(params)
            .subscribe((event: any) => {
                if (event.type === HttpEventType.UploadProgress){
                    this.uploadStatus = Math.round(event.loaded / event.total * 100)
                } else if (event.type === HttpEventType.Response){
                    //this.resetPostForm();
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: event.body.message
                    });
                    // console.log(event.body.data);
                   this.router.navigate(['solo/news/list']);
                }
            }, error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error.message
                });
            });
        }
});
}

getConfirmation(header: string, message: string) {
    return new Promise((resolve, reject) => {
        this.confirmationService.confirm({
            message: message,
            header: header,
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                resolve(true);
            },
            reject: () => {
                resolve(false);
            }
        });
    });
}

}
