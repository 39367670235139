import { Injectable } from '@angular/core';


@Injectable()
export class WebsiteSidebarService {

    constructor() { }

    
}
