import {Component, OnInit, Renderer2} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {CommunityService} from './community.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {HttpClient} from '@angular/common/http';
import { ProfileService } from '../../pages/profile/profile.service';
import { generalHelper } from "../../helpers/generalHelper";
import Swal from "sweetalert2";
import { environment } from 'enviornments/environment';

@Component({
    selector: 'app-community',
    templateUrl: './community.component.html',
    styleUrls: ['./community.component.scss']
})
export class CommunityComponent implements OnInit {
    env=environment;
    generalHelper = generalHelper;
    test: Date = new Date();
    focus;
    focus1;
    followers:any=[];
    allFollowers:any=[];
    totalFollowers = 0;
    user:any;
    showRecord: any = 100;
    totalPolicyList: any = 100;
    FollowersEventNextItr: number;
    allGroupList:any=[];
    groupDiv:boolean=false;
    allUsersList:boolean=true;
    constructor(private fb: FormBuilder, private profileService: ProfileService,private CommunityService: CommunityService,public http:HttpClient,private router: Router, private confirmationService: ConfirmationService, private messageService: MessageService) { }

    

    ngOnInit() {
        
        this.getFollowersList();
        this.getUserProfile();
        this.getallGroupList();
    }

    switchToUserProfile(username){
        this.router.navigateByUrl(`userprofile/${username}`);
    }

    getallGroupList() {
        this.CommunityService.allGroupList().then((res) => {
          //  console.log('ggggggg',res)
            if (res) {
                console.log('group',res);
                this.allGroupList = res;
            }
        }).catch((error) => {});
    }
    GroupList(){
        this.groupDiv=true;
        this.allUsersList =false;
    }
    allUserList(){
        this.groupDiv=false;
        this.allUsersList =true;
    }
    getFollowersList() {
        this.CommunityService.getFollowersList().then((res) => {
            if (res.success) {
                this.manageFollowers(res.data, 0);
                this.followers = res.data;
            }
        }).catch((error) => {});
    }

    filterFollowers(event) {
        this.allFollowers = [];
        if (event) {
          this.FollowersSearch(this.followers, event);
          this.totalFollowers	=	this.followers.length;
        } else {
          this.manageFollowers(this.followers, 0);
          this.totalFollowers	=	this.followers.length;
        }
      }
      FollowersSearch(array, search) {
        return array.filter((el) => {
          for (const param in el) {
            if (el.hasOwnProperty(param) ) {
              if ( el[param] &&  el[param].toString().toLowerCase().indexOf(search.toLowerCase()) !== -1) {
                this.allFollowers.push(el);
                break;
              }
            }
          }
        });
      }
    
      manageFollowers(data, itr) {
        this.allFollowers = [];
        const startItr    = (itr * this.showRecord);
        const endItr      = (startItr + this.showRecord) - 1;
        for (let i = 0; i < data.length; i++) {
          if (i >= startItr && i <= endItr) {
            this.allFollowers.push(data[i]);
          }
        }
        this.FollowersEventNextItr = startItr + 1;
    
      }
    getUserProfile() {
        this.profileService.profile().subscribe(res => {
            this.user = res.id;
        },error => {});
    }
    async requestFollow(ID) {
        const params = {
            'to_user': ID,
            'from_user':this.user
        }
        Swal.fire({
            icon: 'info',
            title: 'Send Request',
            showCancelButton: true,
            html: 'Do you want to send your request ?',
            confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                this.CommunityService.requestFollow(params).subscribe((res: any) => {
                    this.getFollowersList();
                    if(res.success){
                        this.getUserProfile();
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: res.message
                        });
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: res.message
                        });
                    }
                }, (err: any) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: err.error
                        
                    });
                });
            }
    });

    } 

    async requestUnFollow(requestId,action) {
        
        const params = {
            requestId: requestId,
            action : action
        }
        Swal.fire({
            icon: 'warning',
            title: 'Action Request',
            showCancelButton: true,
            html: 'Do you want to take this action ?',
            confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                this.CommunityService.requestUnFollow(params).subscribe((res: any) => {
                    this.getFollowersList();
                    if(res.success){
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: res.message
                        });
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: res.message
                            
                        });
                    }
                }, (err: any) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: err.message
                        
                    });
                });
            }
    });

    }

    getConfirmation(header: string, message: string) {
        return new Promise((resolve, reject) => {
            this.confirmationService.confirm({
                message: message,
                header: header,
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    resolve(true);
                },
                reject: () => {
                    resolve(false);
                }
            });
        });
    }


    


    
}
