import { Injectable } from '@angular/core';
import { BaseService } from '../_services/base.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class vendorRegistrationService {

    constructor(private baseService: BaseService,private http: HttpClient) { }
    private countriesJson = '../assets/phoneCode/phonecode.json';

    public getCountryJSON(): Observable<any> {
        return this.http.get(this.countriesJson);
    }

    signUp(data){
        return this.baseService.post(`auth/register`, data);
    }

    logIn(data){
        return this.baseService.post(`auth/login`, data);
    }

}
