import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { PagesModule } from './pages/pages.module';
import { AppRoutingModule } from './app.routing';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import { AppComponent } from './app.component';
import { AppNavbarComponent } from './shared/navbar/app-navbar.component';
import { ComponentsModule } from './components/components.module';
import { WebsiteModule } from './website/website.module';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { SubscribeService } from './landingtwo/subscribe.service';
import { Subscribe1Service } from './websitelaunch/subscribe1.service';
import { HttpClientModule } from '@angular/common/http';
import { BaseService } from './_services/base.service';
import { AuthService } from './services/auth.service';
import { ProfileService } from './pages/profile/profile.service';

import { SignUpService } from './signup/signup.service';
import { vendorRegistrationService } from './vendor_registration/vendor_registration.service';
import { WebsiteService } from './website/website/website.service';
import { ForumService } from './website/forum/forum.service';
import { CommunityService } from './website/community/community.service';
import { WebsiteSidebarService } from './websitesidebar/websitesidebar.service';
import { TraveloguesService } from './website/travelogues/travelogues.service';
import { AuthGuardService } from './services/auth-guard.service';
import { AppFooterComponent } from './shared/footer/app-footer.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { OtpScreenComponent } from './signup/otpscreen/otpscreen.component';
import { vendorRegistrationComponent } from './vendor_registration/vendor_registration.component';
import { AccountVerificationComponent } from './account_verification/account_verification.component';
import { CopyClipboardComponent } from './copy-clipboard/copy-clipboard.component';
import { SharedModule} from './shared/shared.module';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { HomeService } from './website/home/home.service';
import { NewsService } from 'app/website/news/news.service';
import {ResetPasswordComponent} from './reset_password/reset_password.component'
import { ValidationsService } from './services/validations.service';
import { ToastModule } from 'primeng/toast';
import {CookieService} from "ngx-cookie-service";
import {CookiesService} from "./shared/services/cookies.services";
import {EncryptionService} from "./shared/services/encryption.services";
import {Globals} from "./globals";
import { AngularEditorModule } from '@kolkov/angular-editor';
import { LeftSidebarService } from './sidebars/leftsidebar/leftsidebar.service';

@NgModule({
  declarations: [
    AppComponent,
    AppNavbarComponent,
    AppFooterComponent,
    LoginComponent,
    SignupComponent,
    OtpScreenComponent,
    vendorRegistrationComponent,
    AccountVerificationComponent,
    CopyClipboardComponent,
    ResetPasswordComponent
  ],
  imports: [
        BrowserModule,
        CommonModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        ComponentsModule,
        WebsiteModule,
        AppRoutingModule,
        HttpClientModule,
        PagesModule,
        BrowserAnimationsModule,
        CalendarModule,
        DialogModule,
        DropdownModule,
        SharedModule,
        NgxDocViewerModule,
        ProgressSpinnerModule,
        ToastModule,
        AngularEditorModule
  ],
  providers: [
      BaseService,
      AuthService,
      SubscribeService,
      Subscribe1Service,
      ProfileService,
      SignUpService,
      vendorRegistrationService,
      WebsiteService,
      ForumService,
      CommunityService,
      TraveloguesService,
      AuthGuardService,
      WebsiteSidebarService,
      HomeService,
      NewsService,
      ValidationsService,
      CookieService,
      CookiesService,
      EncryptionService,
      Globals,
      LeftSidebarService
    ],
  exports: [PagesModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
