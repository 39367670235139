import { Component, OnInit } from '@angular/core';
import {ProfileSetupService} from '../pages/profilesetup/profilesetup.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router,ActivatedRoute} from '@angular/router';
import Swal from "sweetalert2";

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset_password.component.html',
    styleUrls: ['./reset_password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    loading: boolean;
    errors: boolean;
    resetPasswordForm: FormGroup;
    token: any;
    constructor(private Service: ProfileSetupService, private fb: FormBuilder, private router: Router,private route: ActivatedRoute) { }

    ngOnInit() {
        this.token = this.route.snapshot.params.resetId;
        this.resetPasswordForm = this.fb.group({
            password: ['', [Validators.required, Validators.minLength(8),
                Validators.pattern('(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[$@$!%*?&])[A-Za-z0-9\d$@$!%*?&].{8,}')]],
            confirm_password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(50)]]
        },{ validator: this.matchPassword});
    }


    get password() {
        return this.resetPasswordForm.controls.password
    }

    get confirm_password() {
        return this.resetPasswordForm.controls.confirm_password
    }

    matchPassword(form){
        if(form.controls.password.value  !== form.controls.confirm_password.value){
          form.controls.confirm_password.setErrors({notEquivalent: true})
        }
        else{
          if(form.controls.password.valid){
            form.controls.confirm_password.setErrors(null)
          }
        }
    }

    resetPassword(formData) {
        const params = {
            'token':this.token,
            'password': formData.password,
            'confirm_password': formData.confirm_password,
        }
        if(formData.password != formData.confirm_password){
            Swal.fire('Error occurred', 'Confirm password is not same as password', 'error');
            return false;
        }
        this.Service.resetPassword(params).subscribe( result => {
            if (result.success){
                Swal.fire('Reset Password', result.message, 'success').then((res) => {
                    window.location.href =  '/login';
                    });
            } else {
                Swal.fire('Error occurred', result.message, 'error');
            }
        });
    }
}
