import {Component, OnInit} from '@angular/core';
import {EditProfileService} from './editprofile.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {ConfirmationService} from 'primeng/api';
import { ProfileService } from '../profile.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import Swal from "sweetalert2";
import { generalHelper } from "../../../helpers/generalHelper";
import { ValidationsService } from '../../../services/validations.service';
import { Globals } from 'app/globals';
import { CookiesService } from 'app/shared/services/cookies.services';
import { EncryptionService } from 'app/shared/services/encryption.services';

@Component({
    selector: 'app-solo-editprofile',
    templateUrl: './editprofile.component.html',
    styleUrls: ['./editprofile.component.scss']
})
export class EditProfileComponent implements OnInit {
    generalHelper = generalHelper;
    user: any = {};
    loading: boolean;
    errors: boolean;
    display:boolean;
    labeltext:any;
    EditProfileForm: FormGroup;
    DOBSelectedDates: Date;
    contentLoader:boolean=false;
    constructor(private cookieService: CookiesService, private encryptionServices: EncryptionService, private globals: Globals,private customValidate: ValidationsService,private EditprofileService: EditProfileService, private profileService: ProfileService,private confirmationService: ConfirmationService, private messageService: MessageService, private router: Router, private fb: FormBuilder) { }

    ngOnInit() {
        this.getUserProfile();
        this.EditProfileForm = this.fb.group({
            account_name: ['', [Validators.required]],
            name: ['', [Validators.required]],
            email: ['', [Validators.required]],
            email_privacy: ['Public', [Validators.required]],
            mobile: ['', [Validators.required]],
            mobile_privacy: ['Public', [Validators.required]],
            dob: ['', [Validators.required]],
            dob_privacy: ['Public', [Validators.required]],
        });
    }
    // showDialog(type) {
    //     this.display = true;
    //     if(type=='email'){
    //         this.labeltext='enter email otp'
    //     }
    //     else{this.labeltext='enter phone otp'}
    // }

    get account_name() {
        return this.EditProfileForm.controls.account_name
    }

    get name() {
        return this.EditProfileForm.controls.name
    }

    get email() {
        return this.EditProfileForm.controls.email
    }

    get email_privacy() {
        return this.EditProfileForm.controls.email_privacy
    }

    get mobile() {
        return this.EditProfileForm.controls.mobile
    }

    get mobile_privacy() {
        return this.EditProfileForm.controls.mobile_privacy
    }

    get dob() {
        return this.EditProfileForm.controls.dob
    }

    get dob_privacy() {
        return this.EditProfileForm.controls.dob_privacy
    }

    async editProfile(formData) {
        const params = {
            'account_name': formData.name,
            'name': formData.account_name,
            'email': formData.email,
            'email_privacy': formData.email_privacy,
            'mobile': formData.mobile,
            'mobile_privacy': formData.mobile_privacy,
           // 'dob': formData.dob.toLocaleDateString(),
            'dob': this.customValidate.formatDate(formData.dob),
            'dob_privacy': formData.dob_privacy,
        }
        Swal.fire({
            icon: 'info',
            title: 'Edit Profile',
            showCancelButton: true,
            html: 'Do you want to edit this profile ?',
            confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                this.contentLoader=true;
            this.EditprofileService.editProfile(params).subscribe((res: any) => {
                if(res.success){
                    
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: res.message
                    }).then( function(){window.location.href = '/solo/dashboard';}
                    );
                    this.getUserProfile();
                    
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: res.message
                    });
                }
                    
                }, (err: any) => {
                    this.errors = true;
                    this.contentLoader=false;
                    // this.messageService.add({severity: 'error', summary: 'Error', detail: err.error ? err.error.error : ""})
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: err.message
                        
                    });
                });
            }
    });

    }
    

    getUserProfile() {
        
        this.profileService.profile().subscribe(res => {
            this.user = res;
            this.cookieService.set(this.globals.tw_user_cookie, this.encryptionServices.encrypt(JSON.stringify(res)));
            if (res) {
                this.EditProfileForm.patchValue({
                    account_name: this.user.name,
                    name: this.user.nickname,
                    email: this.user.email,
                    mobile: this.user.mobile,
                    email_privacy: this.user.email_privacy,
                    mobile_privacy: this.user.mobile_privacy,
                    dob_privacy: this.user.dob_privacy,
                    dob: this.user.dob,
                });
                this.DOBSelectedDates=this.user.dob;
                localStorage.removeItem('Currentuser');
                localStorage.removeItem('endorsedBrands');
                sessionStorage.setItem("Currentuser", JSON.stringify(res));
                sessionStorage.setItem("endorsedBrands", JSON.stringify(res.endorsedBrands));
            }
        },error => {});
    }

    getConfirmation(header: string, message: string) {
        return new Promise((resolve, reject) => {
            this.confirmationService.confirm({
                message: message,
                header: header,
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    resolve(true);
                },
                reject: () => {
                    resolve(false);
                }
            });
        });
    }

    
}
