import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SubscribeService} from "./subscribe.service";
import Swal from 'sweetalert2';
import {Observable} from "rxjs/Observable";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Component({
    selector: 'app-landing',
    templateUrl: './landingtwo.component.html',
    styleUrls: ['./landingtwo.component.scss']
})

export class LandingtwoComponent implements OnInit {
  focus: any;
  focus1: any;
  apiUrl: any;

  constructor(private fb:FormBuilder,private subscriptionService:SubscribeService,public http:HttpClient) { }
    subscriptionForm : FormGroup;

  ngOnInit() {
      let navbar = document.getElementsByTagName('app-navbar')[0].children[0];
      navbar.classList.add('navbar-hidden');
      let footer = document.getElementsByTagName('app-footer')[0].children[0];
      footer.classList.add('navbar-hidden');
      this.subscriptionForm = this.fb.group({
          type: ['',[Validators.required]],
          email:['',[Validators.required,Validators.email]]
      })
  }

    get type(){
        return this.subscriptionForm.controls.type;
    }

    get email(){
        return this.subscriptionForm.controls.email;
    }

    subscribe(formData: any) {
      this.subscriptionService.subscribeNewsletter(formData).subscribe( result => {
          if (result.success){
              Swal.fire('Thank you', result.message, 'success');
              this.subscriptionForm.reset();
          } else {
              Swal.fire('Error occurred', result.message, 'error');
          }
      });
    }

    /*subscribe(): Observable<{data: SubscribeService[]}>{
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.get<{status_code: number, data: SubscribeService[], message: string}>(this.apiUrl +
            'allUsers', {headers: headers});
    }*/
}
