import { Component, OnInit } from '@angular/core';
import { soloTravelHistoryService } from '../soloTravelHistory.service';
import { Router } from '@angular/router';
import {MessageService} from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import { AuthService } from 'app/services/auth.service';
import { environment } from 'enviornments/environment';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'app-solotravelhistorylisting',
    templateUrl: './solotravelhistorylisting.component.html',
    styleUrls: ['./solotravelhistorylisting.component.scss']
})

export class SoloTravelHistoryListingComponent implements OnInit {
    env=environment;
    soloHistory: any = [];
    loading: boolean;
    errors: boolean;
    options: any;
    city:any;
    state:any;
    country:any;
    totalkm:any;
    description:any;
    tripname:any;
    overlays: any[];
    display: boolean = false;
    user:any;
    Viewimages :any;
    // tslint:disable-next-line:no-shadowed-variable
    constructor(private soloTravelHistoryService: soloTravelHistoryService,private authService: AuthService, private router: Router, private confirmationService: ConfirmationService, private messageService: MessageService) { }

    ngOnInit() {
        this.getSoloTravelHistory();
        this.user = this.authService.getLoggedInUser();
        this.options = {
            center: {lat: 36.890257, lng: 30.707417},
            zoom: 12

        };
    }
    getSoloTravelHistory() {
        this.soloTravelHistoryService.getSoloTravelHistory().then((res) => {
            if (res.success) {
                //this.soloHistory = res.data; // Assuming res.data is an array of post objec
                this.soloHistory = res.data.map(entry => {
                    return {
                      ...entry,
                      files: JSON.parse(entry.files)
                    };
                  });
            }
        }).catch((error) => {
            // Handle error here
        });
    }

    getImageUrl(attachmentName: string,id:any): string {
        return attachmentName.replace('public',this.env.imageURL);
      }

    switchToHistoryView(id){
        this.router.navigateByUrl(`solo/travel-history/view/${id}`);
    }
    addHistory(){
        this.router.navigate(['solo/travel-history/create'])
    }

    switchToRoutesEdit(id){
        this.router.navigateByUrl(`solo/travel-history/edit/${id}`);
    }
    

    showDialog(i) {
        this.display = true;
        this.city=this.soloHistory[i].city;
        this.country=this.soloHistory[i].country;
        this.totalkm=this.soloHistory[i].total_km;
        this.state=this.soloHistory[i].state;
        this.description=this.soloHistory[i].description;
        this.tripname=this.soloHistory[i].trip_name;
    }

    deleteTravelSoloHistory(id) {

        this.confirmationService.confirm({
            message: 'Do you want to delete this history',
            header: 'Delete Travel History Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                return this.soloTravelHistoryService.deleteTravelSoloHistory(id).subscribe((res) => {
                    if (res.success) {
                        this.messageService.add({severity: 'success', summary: 'Success', detail: res.message});
                        this.getSoloTravelHistory();
                    } else {
                        this.messageService.add({severity: 'error', summary: 'Error', detail: res.message});
                        this.getSoloTravelHistory();
                    }
                }), err => {
                    this.getSoloTravelHistory();
                };

            },
            reject: () => {}
        });

    }

    getConfirmation(header: string, message: string) {
        return new Promise((resolve, reject) => {
            this.confirmationService.confirm({
                message: message,
                header: header,
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    resolve(true);
                },
                reject: () => {
                    resolve(false);
                }
            });
        });
    }
}
