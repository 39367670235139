import { Component, OnInit } from '@angular/core';
import { SendMessageService } from './send_message.service';

@Component({
    selector: 'app-send_message',
    templateUrl: './send_message.component.html',
    styleUrls: ['./send_message.component.scss']
})

export class SendMessageComponent implements OnInit {
    user: any = {};
    loading: boolean;
    errors: boolean;
    display:boolean;
    
    
    constructor(private SendMessageService: SendMessageService) { }
    

    ngOnInit() {
        this.getUserProfile();
    }
    

    getUserProfile() {
        this.SendMessageService.profile().toPromise().then(res => {
            this.user = res.data;
        }).catch(e => {});
    }
    showDialog(type) {
        this.display = true;
    
    }
}
