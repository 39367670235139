import { Component, OnInit } from '@angular/core';
import { TravelPlanService } from '../groupTravelHistory.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router,ActivatedRoute} from '@angular/router';
import {MessageService} from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import {} from 'googlemaps';
import Swal from 'sweetalert2';
import {Loader} from "@googlemaps/js-api-loader";
import {environment} from "enviornments/environment"
import { generalHelper } from "../../../../helpers/generalHelper";
import { AuthService } from 'app/services/auth.service';
import {HttpEventType} from '@angular/common/http';

@Component({
    selector: 'app-viewTravelPlan',
    templateUrl: './view.component.html',
    styleUrls: ['./view.component.scss']
})

export class ViewTravelPlanComponent implements OnInit {
    env=environment;
    generalHelper = generalHelper;
    user:any;
    loading: boolean;
    errors: boolean;
    startLatLng: any;
    endLatLng: any;
    getStartAddress:any;
    getEndAddress:any;
    soloHistory: any = {
        files : null
    };
    itinerariesArr = [];
    StartLocation:any='';
    EndLocation:any='';
    showItineraryMap2=false;
    groupSerialID:any;
    slides: any = [];
    images: any=[];
    itineraryList:any=[];
    contentLoader=false;
    checklist:any={};
    selectedFile: File | null = null; // Variable to store the selected file
    budgetingData:any;
   
    showForm: boolean = false;
    addExpenseInfo:boolean=false;
    viewTabInfo:boolean=true;
    expenses: any[] = [];
    totalCost: number = 0;
    addExpenseForm:boolean=false;
    expenseForm: FormGroup;
    travelPlanSerialId:any;
    attachmentPreview: string | null = null;
    imagePreview: string | null = null;
    joinData:any = {};
    expenseAttachements:any;
    expenseAttachemntsFile:any;
    transactionId = ''; 
    constructor(private travelPlanService: TravelPlanService, private confirmationService: ConfirmationService, private messageService: MessageService, private fb: FormBuilder, private router: Router, private route: ActivatedRoute,private authService: AuthService) { 
    }

    ngOnInit() {
        this.getTravelHistoryPatch();
        this.initGMap();
        this.groupSerialID=this.route.snapshot.params.editId;
        this.user = this.authService.getLoggedInUser();
        this.createExpenseForm();
    }

    paymentInfo:any;

    getTravelHistoryPatch() {
        this.contentLoader=true;
        let self=this;
        this.travelPlanService.getGroupTravelHistoryEdit(this.route.snapshot.params.editId).then((res) => {
            if(res.success){
                this.soloHistory = res.data;
                this.travelPlanSerialId = res.data.uuid;
                this.itineraryList= this.soloHistory.itinerary ? JSON.parse(this.soloHistory.itinerary) : [];
                this.budgetingData= this.soloHistory.budgeting ? JSON.parse(this.soloHistory.budgeting) : [];
                this.checklist = this.soloHistory.checklist ? JSON.parse(this.soloHistory.checklist) : {};
                this.startLatLng = JSON.parse(res.data.start_latLng);
                this.endLatLng = JSON.parse(res.data.end_latLng);
                if(res.data.files){
                    this.slides = JSON.parse(res.data.files);
                    self.images = [];
                    for(let file of self.slides){
                        self.images.push({previewImageSrc:file.attachmentName.replace('public',self.env.imageURL), thumbnailImageSrc:file.attachmentName, alt:file.attachmentName.replace('public',self.env.imageURL), title:file.attachmentName});
                    }
                }  
                if (res.data.payment_information) {
                    this.paymentInfo = JSON.parse(res.data.payment_information);
                } else {
                    this.paymentInfo = null;
                }            
                if (this.endLatLng) {
                    let map = new google.maps.Map(document.getElementById("gmap") as HTMLElement, {
                        center: {lat: 28.7041, lng: 77.1025},
                        zoom: 6,
                    });
                    this.calcRoute(map, new google.maps.DirectionsService(), new google.maps.DirectionsRenderer());
                }
                this.contentLoader=false;
            }
        });
    }



    initGMap() {
        const loader = new Loader({
            apiKey: environment.gMapKey,
            version: "weekly",
            libraries: ["places","drawing","geometry"]
        });

        loader.load().then(() => {
            let center = {lat: 28.7041, lng: 77.1025}, map: google.maps.Map;

            map = new google.maps.Map(document.getElementById("gmap") as HTMLElement, {
                center: center,
                zoom: 6,
            });
        });
    }

    calcRoute(map, directionsService, directionsDisplay) {
        var start = new google.maps.LatLng(this.startLatLng.lat, this.startLatLng.lng);
        var end = new google.maps.LatLng(this.endLatLng.lat, this.endLatLng.lng);
        var request = {
            origin: start,
            destination: end,
            travelMode: google.maps.TravelMode.DRIVING
        };
        directionsService.route(request, function(response, status) {
            if (status == google.maps.DirectionsStatus.OK) {
                directionsDisplay.setDirections(response);
                directionsDisplay.setMap(map);
            } else {
                alert("Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status);
            }
        });
    }

    makeAutoComplete(type,index) {                                                            
        this.itinerariesArr.push({
            day: index+1,
            start : {
                placeData:  this.itineraryList[index].start.placeData,
                location: this.itineraryList[index].start.location,
                latLng:  this.itineraryList[index].start.latLng
            },
            end : {
                placeData:  this.itineraryList[index].end.placeData,
                location: this.itineraryList[index].end.location,
                latLng:  this.itineraryList[index].end.latLng
            },
            routeData : '',
            totalKMS :  this.itineraryList[index].totalKMS
        });
        
        const loader = new Loader({
            apiKey: environment.gMapKey,
            version: "weekly",
            libraries: ["places","drawing","geometry"]
        });
        loader.load().then(() => {
            let center = {lat: 28.7041, lng: 77.1025}, map: google.maps.Map, service = new google.maps.DirectionsService(), display = new google.maps.DirectionsRenderer(), defaultBounds = {
                north: center.lat + 0.1,
                south: center.lat - 0.1,
                east: center.lng + 0.1,
                west: center.lng - 0.1,
            },itinerary = this.itinerariesArr[0];
            const options = {
                bounds: defaultBounds,
                componentRestrictions: { country: "in" },
                fields: ["address_components", "geometry", "icon", "name"],
                strictBounds: false,
                types: ["establishment"],
            };
           
            if (type == 'show') {
                this.StartLocation=itinerary.start.location;
                this.EndLocation=itinerary.end.location;
                this.showMapElement().then(res => {
                    if (res) {
                        map = new google.maps.Map(document.getElementById("itineraryMap") as HTMLElement, {
                            center: center,
                            zoom: 6,
                        });
                        this.calcItineraryRoute(index, map, service, display, itinerary);
                    }
                });
               
            }
        });
        
    }

    calcItineraryRoute(index, map = null, directionsService, directionsDisplay, itinerary) {
        var start = new google.maps.LatLng(itinerary.start.latLng.lat, itinerary.start.latLng.lng);
        var end = new google.maps.LatLng(itinerary.end.latLng.lat, itinerary.end.latLng.lng);
        var request = {
            origin: start,
            destination: end,
            travelMode: google.maps.TravelMode.DRIVING
        };
        directionsService.route(request, function(response, status) {
            if (status == google.maps.DirectionsStatus.OK) {
                itinerary.routeData = response.routes[0];
                itinerary.totalKMS = response.routes[0].legs[0].distance.text;
                if (map){
                    directionsDisplay.setDirections(response);
                    directionsDisplay.setMap(map);
                }
            } else {
                alert("Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status);
            }
        });
    }

    async showMap(idx: number) {
        this.itinerariesArr=[];
        this.showItineraryMap2 = true;
        this.makeAutoComplete('show',idx);
    }


    async showMapElement() {
        return this.showItineraryMap2 = true;
    }

    async HideMap(){
        this.showItineraryMap2 = false;
    }

    addMember() {
        if (this.joinData.members.length < 5) {
          this.joinData.members.push({ full_name: '', contact_number: '', emergency_contact: '', emergency_contact_2: '', blood_group: '', conveyance_type: '', registration_number: '', status: 0, amount: 1000, transaction_id: '' });
        }
      }
    
      removeMember(index: number) {
        this.joinData.members.splice(index, 1);
      }
    

    showJoinForm() {
        this.showForm = true; // Assuming you have a boolean variable showForm to toggle the visibility
        this.joinData = {
            members: [
              { full_name: '', contact_number: '', emergency_contact: '', emergency_contact_2: '', blood_group: '', conveyance_type: '', registration_number: '', status: 0, amount: 1000, transaction_id: '' }
            ]
          };
        
      }



      showQRCode:boolean=false;  
    showPaymentQR() {
        this.showQRCode = true;
    }

    showTransactionIdInput = false;
    confirmPayment(confirm: boolean) {
        if (confirm) {
          this.showTransactionIdInput = true;
          this.showQRCode = false;
        } else {
          this.showQRCode = false;
        }
      }

  submitTransactionId() {
    this.joinData.members.forEach(member => {
      member.status = 1;
      member.transactionId = this.transactionId;
    });
    this.travelPlanService.joinTrip(this.joinData.members,this.travelPlanSerialId).subscribe(response => {
        console.log('8888',response);
        if(response.type === HttpEventType.Response){
                // Handle response
            this.showTransactionIdInput = false;
            this.showForm = false;
            Swal.fire('Group Joined Succcessfully');
        }

    }, error => {
      // Handle error
      this.showTransactionIdInput = false;
    });
  }



      // Method to handle file selection
    onFileSelected(event: any) {
        // Get the selected file from the event
        this.selectedFile = event.target.files[0];
        console.log("Selected file:", this.selectedFile);
        // You can perform further processing here if needed, such as displaying the file name
    }


      showExpenseTab(){
        this.addExpenseInfo=true;
        this.viewTabInfo=false;
        this.getExpensesList();
      }

      addExpense(){
        this.addExpenseForm=true;
      }
      
      backToView(){
        this.viewTabInfo=true;
        this.addExpenseInfo=false;;
      }


      createExpenseForm() {
        this.expenseForm = this.fb.group({
            expense_title: ['', Validators.required],
            expense_description: ['', Validators.required],
            expense_amount: ['', Validators.required],
           // attachements: [null] // Assuming you want to handle the image upload separately
        });
      }

    get expense_title() {
        return this.expenseForm.controls.expense_title;
    }
    get expense_description() {
        return this.expenseForm.controls.expense_description;
    }
    get expense_amount() {
        return this.expenseForm.controls.expense_amount;
    }

    saveExpense(data) {
        if (this.expenseForm.valid) {
          const formData = new FormData();
          formData.append('id', '0');
          formData.append('expense_title', data.expense_title);
          formData.append('expense_description', data.expense_description);
          formData.append('expense_amount', data.expense_amount);
          for (let i = 0; i < this.expenseAttachements.length; i++) {
            formData.append('attachments[]', this.expenseAttachements[i]);
        }
          
          this.travelPlanService.saveExpenses(formData,this.travelPlanSerialId).subscribe(response => {
                console.log('5566',response)
                if(response.type === HttpEventType.Response){
                    // Handle success response
                    console.log('Expense added successfully:', response);
                    // Optionally, you can reset the form after successful submission
                    this.expenseForm.reset();
                    this.attachmentPreview = null;
                    this.addExpenseInfo=false;
                    this.getExpensesList();
                }else{
                    console.error('Failed to add expense');
                }
              
            },
            (error) => {
              // Handle error response
              console.error('Failed to add expense:', error);
            }
          );
        }
      }
      
      
      
      handleAttachmentInput(e: any) {
        this.expenseAttachements = [];
        this.expenseAttachemntsFile = [];
        if (e.target.files && e.target.files.length) {
            const slides = [];
            for (let i = 0; i < e.target.files.length; i++) {
               
                this.expenseAttachements.push(e.target.files[i]);
                const reader = new FileReader();
                reader.onload = function(el) {
                    slides.push(el.target.result);
                }
                reader.readAsDataURL(e.target.files[i]);
            }
            this.expenseAttachemntsFile = slides;
        }
      }



      getExpensesList() {
        this.contentLoader=true;
        this.travelPlanService.getExpensesList(this.travelPlanSerialId).then((res) => {
            if(res.success){
                this.expenses = res.data;
                
                this.contentLoader=false;
            }else{
                Swal.fire('Empty List', 'No expenses found');
            }
        });
    }

    parseAttachments(attachmentsString: string): any[] {
        try {
            const attachments = JSON.parse(attachmentsString);
            return Array.isArray(attachments) ? attachments : [];
        } catch (error) {
            console.error('Error parsing attachments:', error);
            return [];
        }
    }
    
    prependUrl(attachmentPath: string): string {
        // Replace 'public' with 'https://admin.com' in the attachment path
        return attachmentPath.replace('public', 'https://admin.travwiki.in');
    }
    
          
          
}