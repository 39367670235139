import { Component, OnInit } from '@angular/core';
import {AuthService} from '../services/auth.service';
import {ProfileSetupService} from '../pages/profilesetup/profilesetup.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import Swal from "sweetalert2";
import { CookiesService } from '../shared/services/cookies.services';
import {Globals} from "../globals";
import { EncryptionService } from '../shared/services/encryption.services';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loading: boolean;
    errors: boolean;
    loginForm: FormGroup;
    focus1: boolean;
    display:boolean;
    forgotPasswordForm: FormGroup;
    loginUrl:boolean = ((this.router.url.split("/"))[1] == 'login');
    constructor(private authService: AuthService, private Service: ProfileSetupService, private fb: FormBuilder, private router: Router, private cookieService: CookiesService, private encryptionServices: EncryptionService, private globals: Globals) { }

    ngOnInit() {
        this.loginForm = this.fb.group({
            email: ['', [Validators.required, Validators.pattern(/^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,9})$/)]],
            password: ['', [Validators.required]]
        });
        this.forgotPasswordForm = this.fb.group({
            username: ['', [Validators.required, Validators.pattern(/^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,9})$/)]],
        });
        if(this.loginUrl){
        const footer1 = document.getElementsByTagName('app-footer')[0].children[0];
        footer1.classList.add('remove-footer-mobile');
        const footer2 = document.getElementsByTagName('footer')[0].children[0];
        footer2.classList.add('remove-footer-mobile');}
    }

    get username() {
        return this.forgotPasswordForm.controls.username
    }

    get email() {
        return this.loginForm.controls.email
    }

    get password() {
        return this.loginForm.controls.password
    }

    login(formData) {
        this.loading = true;
        this.errors = false;
        this.authService.doLogin(formData.email, formData.password)
        .subscribe((res: any) => {
            if (res.success) {
                this.handleCookie(res.data.token,res.data.user);
                this.loading = false;
                // window.location.href = '/solo/dashboard';
            } else {
                Swal.fire('Error','Invalid Credentials','error');
            }
        }, (err: any) => {
            this.loading = false;
            this.errors = true;
        });
    }

    showForgotModal() {
        this.display = true;
    }

    forgotPassword(formData) {
        let params = {'email':formData.username};
        this.Service.forgotPassword(params)
        .subscribe((res: any) => {
            if (res.success) {
                Swal.fire('Forgot Password','Please check your email and reset your password','success');
                this.display = false;
            } else {
                Swal.fire('Error','No account found','error');
            }
        }, (err: any) => {
            this.loading = false;
            this.errors = true;
        });
    }

    handleCookie(generateToken,user) {
        if (this.cookieService.check(this.globals.tw_cookie)) {
            this.cookieService.delete(this.globals.tw_cookie, this.globals.hostname);
            this.cookieService.delete(this.globals.tw_user_cookie, this.globals.hostname);
            localStorage.removeItem('currentUserID');
        }
        

        if (this.cookieService.set(this.globals.tw_cookie, this.encryptionServices.encrypt(generateToken))) {
            if (this.cookieService.check(this.globals.tw_cookie)) {
                this.cookieService.set(this.globals.tw_user_cookie, this.encryptionServices.encrypt(JSON.stringify(user)))                
                localStorage.setItem("currentUserID",JSON.stringify(user.uuid));
                window.location.href = '/solo/dashboard';
            }
        }
    }

    submitForm() {
        this.login(this.loginForm.value);
    }
}
