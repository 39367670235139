import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { BlogService } from '../blog.service';
import { generalHelper } from '../../../helpers/generalHelper';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import Swal from "sweetalert2";
import {HttpEventType} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { imagePathHelper } from "../../../helpers/imagePathHelper";
import { AuthService } from 'app/services/auth.service';
import { environment } from 'enviornments/environment';
@Component({
    selector: 'app-bloges',
    templateUrl: './add_blog.component.html',
    styleUrls: ['./add_blog.component.scss']
})

export class AddBlogComponent implements OnInit {
    env=environment;
    generalHelper = generalHelper;
    @ViewChild('docFile')
    myInputVariable: ElementRef;
    loading: boolean;
    errors: boolean;
    Pricing: any;
    contentLoader:boolean=false;
    Price= false;
    schedulepost: any = false;
    display:any;
    videoOption = false;
    podcastOption = false;
    photographyOption = false;
    contentWriterOption = false;
    addBlogForm: FormGroup;
    scheduleDate = new Date();
    images: any = [];
    video: File = null;
    document: File = null;
    audio: File = null;
    ImageURL: any = [];
    DocumentURL:any;
    VideoURL:any;
    AudioURL:any;
    slides: any[];
    thumbURL: any;
    thumbImage : any;
    uploadStatus: number;
    CategoriesList: any = [];
    displayError:any='';
    editBlogthumbnail:any;
    editBlogImageURL:any=[];
    credfileName:any;
    credFile: any;
    editblogtype:any;
    AllBlogTypeError='';
    htmlContent = '';
    config: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '15rem',
        minHeight: '5rem',
        placeholder: 'Enter text here...',
        translate: 'no',
        defaultParagraphSeparator: 'p',
        defaultFontName: 'Arial',
        toolbarHiddenButtons: [['bold', 'insertVideo','fontName']],
        customClasses: [
          {
            name: 'quote',
            class: 'quote',
          },
          {
            name: 'redText',
            class: 'redText',
          },
          {
            name: 'titleText',
            class: 'titleText',
            tag: 'h1',
          },
        ],
      };
      user:any;
      attachmentHelper:any;
      thumbHelper:any;
    constructor(private blogService: BlogService, public fb: FormBuilder, private authService: AuthService, private router: Router, private route: ActivatedRoute) { }

    ngOnInit() {
        this.user = this.authService.getLoggedInUser();
        this.addBlogInit();
        this.CategoryType();
        this.route.snapshot.params.id ? this.editBlog(this.route.snapshot.params.id) : '';
        this.videoType(this.user.bloggerType);
    }

    filterTab(tabValue) {

        this.Pricing = tabValue;
            if (this.Pricing == 'Charge') {
                this.Price = true;
                this.schedulepost=false;
            }  
           else {
               this.Price= false;
               this.schedulepost=false;
           }
            
    }
    tabcontent(val) {
        if(val=='schedulepost'){
            this.schedulepost=true;
            
        }
    }
    
    videoType(val){
        if(val=='Video Blogs') {this.videoOption=true;this.podcastOption=false;this.photographyOption=false;this.contentWriterOption=false;}
        if(val=='Podcast') {this.videoOption=false;this.podcastOption=true;this.photographyOption=false;this.contentWriterOption=false;}
        if(val=='Photography') {this.videoOption=false;this.podcastOption=false;this.photographyOption=true;this.contentWriterOption=false;}
        if(val=='Write Up') {this.videoOption=false;this.podcastOption=false;this.photographyOption=false;this.contentWriterOption=true;}
        if(val=='All') {this.videoOption=false;this.podcastOption=false;this.photographyOption=false;this.contentWriterOption=false;}
    }

    addBlogInit() {
        this.addBlogForm = this.fb.group({
            id: [0],
            type: [this.user.bloggerType, [Validators.required]],
            title: ['', [Validators.required]],
            description: ['', [Validators.required,Validators.minLength(20)]],
            comments: ['allow', [Validators.required]],
            category: [ ,[Validators.required]],
            keywords: ['',[Validators.required]],
            visibility: ['',[Validators.required]],
            language: ['English'],
            quantity: [1,[Validators.required]],
            restriction: ['No',[Validators.required]],
            amount: [0,[Validators.required]],
            currency: ['INR'],
            fileType: ['',[Validators.required]],
        });
    }
    get id() {
        return this.addBlogForm.controls.id
    }
    get type() {
        return this.addBlogForm.controls.type
    }
    get title() {
        return this.addBlogForm.controls.title
    }
    get description() {
        return this.addBlogForm.controls.description
    }
    get comments() {
        return this.addBlogForm.controls.comments
    }
    get category() {
        return this.addBlogForm.controls.category
    }
    get visibility() {
        return this.addBlogForm.controls.visibility
    }
    get keywords() {
        return this.addBlogForm.controls.keywords
    }
    get language() {
        return this.addBlogForm.controls.language
    }
    get quantity() {
        return this.addBlogForm.controls.quantity
    }
    get amount() {
        return this.addBlogForm.controls.amount
    }
    get currency() {
        return this.addBlogForm.controls.currency
    }
    get fileType() {
        return this.addBlogForm.controls.fileType
    }

    onFileInput(e) {
        if (e.target.files && e.target.files.length) {
            this.thumbImage = <File>e.target.files[0];
        }
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (_event) => {
            this.thumbURL = reader.result;
        }
        this.displayError='';
    }

    onVideoInput(e) {
        this.VideoURL=''
        if (e.target.files && e.target.files.length) {
            this.video = <File>e.target.files[0];
        }
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (_event) => {
            this.VideoURL = reader.result;
            this.ImageURL = [];
            this.DocumentURL = null;
            this.AudioURL = null;
        }
    }

    onAudioInput(e) {
        this.AudioURL='';
        if (e.target.files && e.target.files.length) {
            this.audio = <File>e.target.files[0];
        }
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (_event) => {
            this.AudioURL = reader.result;
            this.ImageURL = [];
            this.DocumentURL = null;
            this.VideoURL=null;
        }
    }

    onImageInput(e) {
        this.images = [];
        if (e.target.files && e.target.files.length) {
            this.DocumentURL = null;
            this.VideoURL=null;
            this.AudioURL=null;
            let slides = [];
            for (var i = 0; i < e.target.files.length; i++) {
                if (this.images.length == 20) {
                    Swal.fire('Limit Exceeded','You are not eligible to upload more then 20 Images','error');
                    return false;
                }
                this.images.push(e.target.files[i]);
                let reader = new FileReader();
                reader.onload = function(el) {
                    slides.push(el.target.result);
                }
                reader.readAsDataURL(e.target.files[i]);
            }
            this.ImageURL = slides;
            // console.log(this.ImageURL);
        }
    }

    onDocInput(e) {
        if (e.target.files && e.target.files.length) {
            this.readCredFile(e);
            this.document = <File>e.target.files[0];
        }
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (_event) => {
            this.DocumentURL = reader.result;
            this.VideoURL=null;
            this.AudioURL=null;
            this.ImageURL=[];
        }
    }

    readCredFile(event: any): void {
        if (event.target.files && event.target.files[0]) {
          const fileName = event.target.files[0].name;
            this.credfileName = fileName;
            this.readFileContent(event.target.files[0]).then(content => {
              this.credFile = content;
            }).catch(error => {
                console.log(error); this.myInputVariable.nativeElement.value = '';
            });
        }
      }

    readFileContent(file) {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onload = event => resolve(reader.result);
          reader.onerror = error => reject(error);
          reader.readAsText(file);
        });
      }
    manageBlog(data) {
        
        //console.log('thhumb',this.thumbImage);
        //console.log('this.route.snapshot.params.id',this.route.snapshot.params.id);
        if(this.route.snapshot.params.id=='' && !this.thumbImage){
            this.displayError='Please select thumbnail image';
            return false;
        }
        if(!this.route.snapshot.params.id && !this.thumbImage){
            this.displayError='Please select thumbnail image';
            return false;
        }
        const params = new FormData();
        params.append('id', data.id);
        params.append('type', data.type);
        params.append('title', data.title);
        params.append('subtitle', 'No Subtitles');
        params.append('description', data.description);
        params.append('visibility', data.visibility);
        params.append('language', data.language);
        params.append('quantity', data.quantity);
        params.append('comments', data.comments);
        if(this.thumbImage){
        params.append('thumbnail', this.thumbImage, this.thumbImage.name);
        }
        params.append('category', data.category);
        params.append('keywords', data.keywords);
        params.append('pricing', data.amount);
        params.append('filetype', data.fileType ? data.fileType : 'NA');
        params.append('schedule', this.scheduleDate.toLocaleDateString());
        params.append('video', this.video);
        params.append('document', this.document);
        params.append('audio', this.audio);
        for (var i = 0; i < this.images.length; i++) {
            params.append("image[]", this.images[i]);
        }
        if(!this.images.length && !this.audio && !this.video && !this.document && !this.editBlogImageURL.length){
            this.AllBlogTypeError ="Please select upload a file to complete this blog."
            return false;
        }
        //console.log('iimg',this.images,'audo',this.audio ,'vid',this.video,'thisdoc',this.document,'editblog',this.editBlogImageURL)
        //return false;
        this.blogService.addUpdate(params).subscribe(event => {
            this.contentLoader=true;
            if (event.type === HttpEventType.UploadProgress){
                this.uploadStatus = Math.round(event.loaded / event.total * 100)
            } else if (event.type === HttpEventType.Response){
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: event.body.message
                });
                this.router.navigate(['solo/blogs']);
            }
        }, error => {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message
            })
            this.contentLoader=false;
        });
    }

    async CategoryType() {
        this.CategoriesList = [];
        await this.blogService.getBusinessType().then((res) => {
            if (res.success) {
                this.CategoriesList = res.data;
            } else {
                this.CategoriesList = [];
            }
        }).catch((back) => {
        });
    }

    private editBlog(id) {
        this.editBlogImageURL=[];
        this.contentLoader=true;
        this.blogService.details(id).toPromise().then(res => {
            if (res.success) {
                this.videoType(res.data.type);
                this.addBlogForm.patchValue({
                    id: this.route.snapshot.params.id,
                    title : res.data.title,
                    description : res.data.description,
                    visibility : res.data.visibility,
                    language : res.data.language,
                    quantity : res.data.quantity,
                    comments : res.data.comments,
                    category : res.data.category,
                    keywords : res.data.keywords,
                    pricing : res.data.pricing,
                    schedule : res.data.schedule,
                    fileType : res.data.filetype,
                });
                this.editblogtype = res.data.type;
                if(res.data.keywords){
                    this.addBlogForm.controls['keywords'].setValue(res.data.keywords.split(','), {onlySelf: true});
                }
                this.editBlogImageURL = res.data.attachments;
                this.editBlogthumbnail = res.data.thumbnail; 
                this.contentLoader=false;
            }
        }).catch((back) => {
            this.contentLoader=false;
        });
    }
}
