export const generalHelper = {
    privacy : ['Public','Followers','Self'],
    group : ['Public','Followers','group'],
    addressType : ['Home','Office'],
    //followStatus : ['Pending','Accept','Deny','Block','Report'],
    travelType : ['Solo','Group','Family','No'],
    userType: {traveler:'Solo Traveler',vendor:'Vendor',company:'Company',other:'Other'},
    followStatus: {Pending:'Pending',Accept:'Accepted',Deny:'Denied',Block:'Blocked',Report:'Reported'},
    yesNo: ['Yes','No'],
    activeInactive: ['Active','Inactive'],
    blogType : ['Podcast','Write Up','Photography','Video Blogs','All'],
    PostType : ['Image','Document','Post','Video'],
};