import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumberOnlyDirective } from '../shared/directives/number.directive';
import { AlphabetsOnlyDirective } from '../shared/directives/alphabets.directive';
import { NumericAlphabetsOnlyDirective } from './directives/numeric_alphabets.directive';
import { NumericAlphabetsSpaceOnlyDirective } from './directives/numeric_alphabets_space.directive';
@NgModule({ 
  imports: [
    CommonModule
  ],
  declarations: [NumberOnlyDirective,AlphabetsOnlyDirective,NumericAlphabetsOnlyDirective,NumericAlphabetsSpaceOnlyDirective],
  exports:[NumberOnlyDirective,AlphabetsOnlyDirective,NumericAlphabetsOnlyDirective,NumericAlphabetsSpaceOnlyDirective],
})
export class SharedModule { }
