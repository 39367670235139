import { Component, OnInit } from '@angular/core';
import { NewsService } from '../news.service';
import { Router } from '@angular/router';
import {MessageService} from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import { AuthService } from 'app/services/auth.service';
import { environment } from 'enviornments/environment';
@Component({
    // tslint:disable-next-line:component-selector
    selector: 'app-Newslisting',
    templateUrl: './listing.component.html',
    styleUrls: ['./listing.component.scss']
})

export class NewsListingComponent implements OnInit {
    env=environment;
    user :any;
    NewsList: any = [];
    loading: boolean;
    errors: boolean;
    constructor(private NewsService: NewsService, private router: Router, private confirmationService: ConfirmationService, private messageService: MessageService, private authService: AuthService) { }

    ngOnInit() {
        this.user = this.authService.getLoggedInUser();
        this.getNewsList();
    }

    getNewsList() {
        this.NewsService.getNewsList().then((res) => {
        if (res.success) {
            this.NewsList = res.data;
        }
        }).catch((error) => {});
        
    }

    switchToNewsView(id){
        this.router.navigateByUrl(`solo/news/view/${id}`);
    }

    addNews(){
        this.router.navigate(['solo/news/add'])
    }

    switchToNewsEdit(id){
        this.router.navigateByUrl(`solo/news/edit/${id}`);
    }
    


    deleteNews(id) {

        this.confirmationService.confirm({
            message: 'Do you want to delete this News',
            header: 'Delete News Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                return this.NewsService.deleteNews(id).subscribe((res) => {
                    if (res.success) {
                        this.messageService.add({severity: 'success', summary: 'Success', detail: res.message});
                        this.getNewsList();
                    } else {
                        this.messageService.add({severity: 'error', summary: 'Error', detail: res.message});
                        this.getNewsList();
                    }
                }), err => {
                    this.getNewsList();
                };

            },
            reject: () => {}
        });

    }

    getConfirmation(header: string, message: string) {
        return new Promise((resolve, reject) => {
            this.confirmationService.confirm({
                message: message,
                header: header,
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    resolve(true);
                },
                reject: () => {
                    resolve(false);
                }
            });
        });
    }
}
