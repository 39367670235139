import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class EncryptionService {
    public keySize      =   256;
    public ivSize       =   128;
    public iterations   =   100;
    public secretKey    =   '!>Ps)}{:`ux4&LKpb/k%]l;+l9'

    constructor() { }  
    
    encrypt(token: string) {           
        var salt = CryptoJS.lib.WordArray.random(128/8);  
        var key = CryptoJS.PBKDF2(this.secretKey, salt, {
            keySize: this.keySize/32,
            iterations: this.iterations
        });
        var iv:any = CryptoJS.lib.WordArray.random(128/8);    
        var encrypted = CryptoJS.AES.encrypt(token, key, { 
            iv: iv, 
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC        
        });    
        // salt, iv will be hex 32 in length
        // append them to the ciphertext for use  in decryption
        var transitmessage = salt.toString()+ iv.toString() + encrypted.toString();
        return transitmessage;   
    }

    decrypt (encryptedToken: string) {
        var salt = CryptoJS.enc.Hex.parse(encryptedToken.substr(0, 32));
        var iv = CryptoJS.enc.Hex.parse(encryptedToken.substr(32, 32))
        var encrypted = encryptedToken.substring(64);
        
        var key = CryptoJS.PBKDF2(this.secretKey, salt, {
            keySize: this.keySize/32,
            iterations: this.iterations
        }); 
    
        var decrypted = CryptoJS.AES.decrypt(encrypted, key, { 
        iv: iv, 
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
        
        })
        return decrypted;
    }

}
