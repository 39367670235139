import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import {MessageService} from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import {UserProfileService} from './../../user_profile.service';
@Component({
    selector: 'app-OtherUserRouteslisting',
    templateUrl: './listing.component.html',
    styleUrls: ['./listing.component.scss']
})

export class OtherUserRoutesListingComponent implements OnInit {
    soloRoute: any = [];
    loading: boolean;
    errors: boolean;
    options: any;
    city:any;
    state:any;
    country:any;
    totalkm:any;
    description:any;
    tripname:any;
    token: any;
    overlays: any[];
    display: boolean = false;
    // tslint:disable-next-line:no-shadowed-variable
    constructor(private UserProfileService: UserProfileService, private router: Router, private confirmationService: ConfirmationService, private messageService: MessageService,private route: ActivatedRoute) { }

    ngOnInit() {
        this.token = this.route.snapshot.params.username;
        this.getSoloRoutes();
    }

    getSoloRoutes() {
        this.UserProfileService.getUserProfile(this.token).subscribe(res => {
        if (res.success) {
            this.soloRoute = res.data.routes_histories;
        }
        },error => {});
        
    }

    switchToRoutesView(id){
        this.router.navigateByUrl(`/solo/routes/view/${id}`);
    }
}
