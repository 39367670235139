import { Component, OnInit } from '@angular/core';
import { LeftSidebarService } from './leftsidebar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { imagePathHelper } from 'app/helpers/imagePathHelper';

@Component({
  selector: 'app-leftsidebar',
  templateUrl: './leftsidebar.component.html',
  styleUrls: ['./leftsidebar.component.css']
})
export class LeftsidebarComponent implements OnInit {

  AllAds:any=[];
  PageVar:any;
  imageHelper = imagePathHelper;
  PageSelected:boolean;
  constructor(private LeftSidebarService: LeftSidebarService,private route: ActivatedRoute,private router : Router) { }

  ngOnInit(): void {
    if(((this.router.url.split("/"))[1] == 'travelogues')){
      this.PageVar='Travelogues';
    }
    if(((this.router.url.split("/"))[1] == '')){
      this.PageVar='Home';
    }
    if(((this.router.url.split("/"))[1] == 'community')){
      this.PageVar='Home';
    }
    if(((this.router.url.split("/"))[1] == 'forum')){
      this.PageVar='Forums';
    }
    if(((this.router.url.split("/"))[1] == 'all-news')){
      this.PageVar='News';
    }
    if(((this.router.url.split("/"))[1] == 'trav-shoppe')){
      this.PageVar='TravShoppe';
    }
    
    
    this.getAds();
    
  }


  getAds() {
    this.LeftSidebarService.list(this.PageVar).subscribe(res => {
        if (res.success) {
          this.AllAds = res.data.filter( el => el.page_level !== 0);
        } else {
          this.AllAds = [];
        }
    }, error => {
      console.error(error)
        this.AllAds = [];
    });
}

}
