import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { GuestGuardService } from './services/guest-guard.service';
import { ComponentsComponent } from './components/components.component';
import { SignupComponent } from './signup/signup.component';
import { OtpScreenComponent } from './signup/otpscreen/otpscreen.component';
import { vendorRegistrationComponent } from './vendor_registration/vendor_registration.component';
import { LoginComponent } from './login/login.component';
import { LandingComponent } from './landing/landing.component';
import { LandingtwoComponent } from './landingtwo/landingtwo.component';
import { WebsiteLaunchComponent } from './websitelaunch/websitelaunch.component';
import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';
import { AccountVerificationComponent } from './account_verification/account_verification.component';
import { WebsiteComponent } from './website/website/website.component';
import { ForumComponent } from './website/forum/forum.component';
import { CommunityComponent } from './website/community/community.component';
import { TraveloguesComponent } from './website/travelogues/travelogues.component';
import {WebsiteSidebarComponent } from './websitesidebar/websitesidebar.component';
import {LeftsidebarComponent } from './sidebars/leftsidebar/leftsidebar.component';
import { HomeComponent } from './website/home/home.component';
import { NewsHomeComponent } from 'app/website/news/newshome/newshome.component';
import {ResetPasswordComponent} from './reset_password/reset_password.component';
import {AppFooterComponent} from './shared/footer/app-footer.component';
import { TraveloguesDetailComponent } from './website/travelogues/detail-page/traveloguesdetail.component';
import { NewsComponent } from './website/news/news.component';
import { NewsDetailComponent } from './website/news/detail-page/newsdetail.component';
const routes: Routes = [
    { path: '', component: HomeComponent},
    { path: 'home', component: HomeComponent },
    { path: '', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) },
    { path: 'login', component: LoginComponent },
    { path: 'signup', component: SignupComponent },
    { path: 'otp-verification/:userID', component: OtpScreenComponent },
    { path: 'vendor_registration', component: vendorRegistrationComponent },
    { path: 'coming-soon', component: LandingtwoComponent },
    { path: 'community', component: CommunityComponent },
    { path: 'travelogues', component: TraveloguesComponent },
    { path: 'travelogues-detail/:uuid', component: TraveloguesDetailComponent },
    { path: 'launching-soon', component: WebsiteLaunchComponent },
    { path: 'website', component: WebsiteComponent },
    { path: 'landing', component: LandingComponent, canActivate: [ AuthGuardService ] },
    {path: 'solo/news', component: NewsHomeComponent},
    { path: 'nucleoicons', component: NucleoiconsComponent, canActivate: [ AuthGuardService ] },
    { path: 'component', component: ComponentsComponent, canActivate: [ AuthGuardService ] },
    { path: 'forum', component: ForumComponent },
    { path: 'websitesidebar', component: WebsiteSidebarComponent},
    { path: 'leftsidebar', component: LeftsidebarComponent},
    { path: 'account-verification/:verificationID', component: AccountVerificationComponent, data: {permission: ['account-verification']} },
    { path: 'account-verification/solo/:userID', component: AccountVerificationComponent, data: {permission: ['account-verification']} },
    { path: 'forgot-password/:resetId', component: ResetPasswordComponent},
    { path: 'footer', component: AppFooterComponent},
    { path: 'all-news', component: NewsComponent},
    { path: 'news-detail/:id', component: NewsDetailComponent},
  ];


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
  ],
})

export class AppRoutingModule { }
