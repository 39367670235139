import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { WebsiteComponent } from './website/website.component';
import { ForumComponent } from './forum/forum.component';
import { CommunityComponent } from './community/community.component';
import { TraveloguesComponent } from './travelogues/travelogues.component';
import { TraveloguesDetailComponent } from './travelogues/detail-page/traveloguesdetail.component';
import {ToastModule} from 'primeng/toast';
import {WebsiteSidebarComponent} from '../websitesidebar/websitesidebar.component';
import {LeftsidebarComponent} from '../sidebars/leftsidebar/leftsidebar.component';
import { HomeComponent } from './home/home.component';
import { NewsHomeComponent } from 'app/website/news/newshome/newshome.component';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { NewsComponent } from './news/news.component';
import { NewsDetailComponent } from './news/detail-page/newsdetail.component';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import {ChipsModule} from 'primeng/chips';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgImageSliderModule } from 'ng-image-slider';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ReactiveFormsModule,
        ToastModule,
        ConfirmDialogModule,
        ScrollPanelModule,
        DialogModule,
        TabViewModule,
        ChipsModule,
        ProgressSpinnerModule,
        NgImageSliderModule   
    ],
    declarations: [
        
        WebsiteComponent,
        ForumComponent,
        CommunityComponent,
        TraveloguesComponent,
        WebsiteSidebarComponent,
        LeftsidebarComponent,
        HomeComponent,
        NewsHomeComponent,
        TraveloguesDetailComponent,
        NewsComponent,
        NewsDetailComponent



    ]
})
export class WebsiteModule { }
