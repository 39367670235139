import { Component, OnInit } from '@angular/core';
import { TraveloguesService } from '../travelogues.service';
import {ActivatedRoute, Router} from "@angular/router";
import { imagePathHelper } from "../../../helpers/imagePathHelper";
import Swal from "sweetalert2";
import { Gallery, GalleryItem, ImageItem } from '@ngx-gallery/core';
import { AuthService } from 'app/services/auth.service';
import { environment } from 'enviornments/environment';

@Component({
    selector: 'app-travelogues-details',
    templateUrl: './traveloguesdetail.component.html',
    styleUrls: ['./traveloguesdetail.component.scss']
})

export class TraveloguesDetailComponent implements OnInit {
    env=environment;
    user : any;
    loading: boolean;
    errors: boolean;
    addcomment:any=true;
    visibility:any;
    logintocomment:any;
    blog: any;
    imgHelper=imagePathHelper;
    commentText: any;
    socialLinkPanel:boolean=false;
    imagedata:any=[];
    loader:boolean=false;
    items: GalleryItem[];
    Linkhref: string = "";
    constructor(private blogService: TraveloguesService, private router: Router, private route: ActivatedRoute,public gallery: Gallery,private authService: AuthService) { }

    ngOnInit() {
        this.user = this.authService.getLoggedInUser();
        this.getBlogDetails(this.route.snapshot.params.uuid);
        this.Linkhref = this.router.url;
        
    }

    shareSociallink(){
        this.socialLinkPanel=true;
    }

    GoToLoginPage(){
        this.router.navigateByUrl(`login`);
    }

    getBlogDetails(id) {
        let self=this;
        this.loader = true;
        this.blogService.details(id).toPromise().then(res => {
            if (res.success){ 
                this.loader = false;;
                self.blog = res.data;                
                for (let data of self.blog.attachments) {
                    let thumb = data.attachment.replace('public',this.env.imageURL);
                    //this.imagedata.push({srcUrl:thumb, previewUrl:thumb});
                    this.imagedata.push({image:thumb, thumbImage:thumb});
                }
            }
            this.items = self.imagedata.map(item => 
                new ImageItem({ src: item.srcUrl, thumb: item.previewUrl })
            );

            


                // Get a lightbox gallery ref
                const lightbox2Ref = this.gallery.ref('test-2');

                // Load items into the lightbox gallery ref
                lightbox2Ref.load(this.items);
                    })
    }

    getBlogDetails2(id) {
        let self=this;
        this.blogService.details(id).toPromise().then(res => {
            if (res.success){ 
                self.blog = res.data;                
                for (let data of self.blog.attachments) {
                    let thumb = data.attachment.replace('public',this.env.imageURL);
                    //this.imagedata.push({srcUrl:thumb, previewUrl:thumb});
                    this.imagedata.push({image:thumb, thumbImage:thumb});
                }
            }
            this.items = self.imagedata.map(item => 
                new ImageItem({ src: item.srcUrl, thumb: item.previewUrl })
            );

            


                // Get a lightbox gallery ref
                const lightbox2Ref = this.gallery.ref('test-2');

                // Load items into the lightbox gallery ref
                lightbox2Ref.load(this.items);
                    })
    }
 
    doComment(event) {
        if(this.commentText.trim()=='') return false;
        if (event.keyCode===13 || event=='Submit') {
            let param = {id:0,blog_id:this.blog.id,comment:this.commentText.trim()};
            this.blogService.comment(param).subscribe(res => {
                if (res.success) {
                    this.commentText = '';
                    this.getBlogDetails2(this.route.snapshot.params.uuid);
                    this.commentText='';
                    Swal.fire('Comment', res.message, 'success');
                }
                else {
                    Swal.fire('Comment', res.message, 'error');
                }
            },error => {
                Swal.fire('Comment', error.message, 'error');
            });
        }
    }

    doLike() {
        let param = {id:0,blog_id:this.route.snapshot.params.id,type:'Like'};
        this.blogService.like(param).subscribe(res => {
            if (res.success) {
                this.getBlogDetails2(this.route.snapshot.params.uuid);
                Swal.fire('Like', res.message, 'success');
            }
            else {
                Swal.fire('Like', res.message, 'error');
            }
        },error => {
            Swal.fire('Like', error.message, 'error');
        });
    }

    switchToUserProfile(username){
        this.router.navigateByUrl(`userprofile/${username}`);
    }

    blogDetails(id){
        this.router.navigateByUrl(`travelogues-detail/${id}`);
        this.getBlogDetails(id);
    }
}
