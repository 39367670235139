import {Component, OnInit, Renderer2} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {GroupSettingsService} from './groupsettings.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import Swal from "sweetalert2";

import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import { generalHelper } from '../../../helpers/generalHelper';

@Component({
    selector: 'app-groupsettings',
    templateUrl: './groupsettings.component.html',
    styleUrls: ['./groupsettings.component.scss']
})
export class GroupSettingsComponent implements OnInit {
    generalHelper = generalHelper;
    forgotpasswordForm: FormGroup;
    AddressForm: FormGroup;
    user: any = {};
    loading: boolean;
    errors: boolean;
    display:boolean;
    addressModal:boolean;
    profile:any;
    features:any;
    privacy:any=true;
    visibility:any;
    security:any;
    rolesauthorization:any;
    banking:any;
    addressList:any=[];
    adress:any;
    editAddress:any=[];
    editbutton:any;
    editaddressModal=false;
    isdefault:any;
    constructor(private GroupSettingsService: GroupSettingsService,private fb: FormBuilder,private confirmationService: ConfirmationService, private messageService: MessageService, private router: Router,) { }

    ngOnInit() {
        this.getAddressList();
        this.forgotpasswordForm = this.fb.group({
            current_password: ['', [Validators.required]],
            new_password: ['', [Validators.required]],
            confirm_password: ['', [Validators.required]],
        });

        this.AddressForm = this.fb.group({
            id:[0],
            contact_name: ['', [Validators.required]],
            type: ['', [Validators.required]],
            pin_code: ['', [Validators.required]],
            contact_number: ['', [Validators.required]],
            alternate_number: ['', [Validators.required]],
            address: ['', [Validators.required]],
            landmark: ['', [Validators.required]],
            country: ['', [Validators.required]],
            state: ['', [Validators.required]],
            is_default: [false],
            
        });
    }

    get current_password() {
        return this.forgotpasswordForm.controls.current_password
    }

    get new_password() {
        return this.forgotpasswordForm.controls.new_password
    }

    get confirm_password() {
        return this.forgotpasswordForm.controls.confirm_password
    }

    get id() {
        return this.AddressForm.controls.id
    }

    get type() {
        return this.AddressForm.controls.type
    }
    get contact_name() {
        return this.AddressForm.controls.contact_name
    }
    get pin_code() {
        return this.AddressForm.controls.pin_code
    }
    get contact_number() {
        return this.AddressForm.controls.contact_number
    }
    get alternate_number() {
        return this.AddressForm.controls.alternate_number
    }
    get address() {
        return this.AddressForm.controls.address
    }
    get country() {
        return this.AddressForm.controls.country
    }
    get state() {
        return this.AddressForm.controls.state
    }
    get is_default() {
        return this.AddressForm.controls.is_default
    }
    
    get landmark() {
        return this.AddressForm.controls.landmark
    }
    
    async forgotpassword(formData) {
        const params = {
            'current_password': formData.current_password,
            'new_password': formData.new_password,
            'confirm_password': formData.confirm_password,
        }
        Swal.fire({
            icon: 'warning',
            title: 'Change password',
            showCancelButton: true,
            html: 'Do you want to change password ?',
            confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                this.GroupSettingsService.changePassword(params).subscribe((res: any) => {
                    this.router.navigate(['/solo/profile/settings']);
                    this.messageService.add({severity: 'success', summary: 'Success', detail: res.message ? res.message : ""});
                }, (err: any) => {
                    this.errors = true;
                    this.messageService.add({severity: 'error', summary: 'Error', detail: err.error ? err.error.error : ""})
                });
            }
    });

    }
    getConfirmation(header: string, message: string) {
        return new Promise((resolve, reject) => {
            this.confirmationService.confirm({
                message: message,
                header: header,
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    resolve(true);
                },
                reject: () => {
                    resolve(false);
                }
            });
        });
    }

    showDialog() {
        
        }
    tabcontent(val) {
        if(val=='profile'){
            this.profile=true;
            this.features=false;
            this.privacy=false;
            this.visibility=false;
            this.security=false;
            this.rolesauthorization=false;
            this.banking=false;
            this.adress=false;
        }
        if(val=='features'){
            this.profile=false;
            this.features=true;
            this.privacy=false;
            this.visibility=false;
            this.security=false;
            this.rolesauthorization=false;
            this.banking=false;
            this.adress=false;
        }
        if(val=='privacy'){
            this.profile=false;
            this.features=false;
            this.privacy=true;
            this.visibility=false;
            this.security=false;
            this.rolesauthorization=false;
            this.banking=false;
            this.adress=false;
        }
        if(val=='visibility'){
            this.profile=false;
            this.features=false;
            this.privacy=false;
            this.visibility=true;
            this.security=false;
            this.rolesauthorization=false;
            this.banking=false;
            this.adress=false;
        }
        if(val=='security'){
            this.profile=false;
            this.features=false;
            this.privacy=false;
            this.visibility=false;
            this.security=true;
            this.rolesauthorization=false;
            this.banking=false;
            this.adress=false;
        }
        if(val=='rolesauthorization'){
            this.profile=false;
            this.features=false;
            this.privacy=false;
            this.visibility=false;
            this.security=false;
            this.rolesauthorization=true;
            this.banking=false;
            this.adress=false;
        }
        if(val=='banking'){
            this.profile=false;
            this.features=false;
            this.privacy=false;
            this.visibility=false;
            this.security=false;
            this.rolesauthorization=false;
            this.banking=true;
            this.adress=false;
        }
        if(val=='adress'){
            this.profile=false;
            this.features=false;
            this.privacy=false;
            this.visibility=false;
            this.security=false;
            this.rolesauthorization=false;
            this.banking=false;
            this.adress=true;
        }
    }
    showDialog10() {
        this.display = true;
    }

    showDialogAddress(type) {
        
        if(type=='edit'){
            this.addressModal = true;
        }else{
            this.addressModal = true;
            this.editAddress.id=0;
            this.AddressForm.reset();
        }
            
    }
      
    getAddressList() {
        this.GroupSettingsService.addressList().subscribe(res => {
            this.addressList = res;
        },error => {});
    }
    
    getRoutesPatch(i) {
            this.editAddress = this.addressList[i];
            if(this.editAddress){
                this.AddressForm.patchValue({
                    id: this.editAddress.id,
                    type: this.editAddress.type,
                    contact_name: this.editAddress.contact_name,
                    pin_code: this.editAddress.pin_code,
                    contact_number: this.editAddress.contact_number,
                    alternate_number: this.editAddress.alternate_number,
                    address: this.editAddress.address,
                    landmark: this.editAddress.landmark,
                    country: this.editAddress.country,
                });
                this.isdefault=this.addressList[i].is_default
            }
            this.showDialogAddress('edit');
    }

    async setdefault(i) {
        this.editAddress = this.addressList[i];
        const params = {
            'id':this.addressList[i].id,
            'type': this.addressList[i].type,
            'contact_name': this.addressList[i].contact_name,
            'pin_code': this.addressList[i].pin_code,
            'contact_number': this.addressList[i].contact_number,
            'alternate_number': this.addressList[i].alternate_number,
            'address': this.addressList[i].address,
            'landmark': this.addressList[i].landmark,
            'country': this.addressList[i].country,
            'state': this.addressList[i].state,
            'is_default': 'Yes',
        }
        Swal.fire({
            icon: 'info',
            title: 'Do you want to add this address',
            showCancelButton: true,
            html: 'Do you want to update privacy ?',
            confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                this.GroupSettingsService.addAddress(params)
                .subscribe((res: any) => {
                    this.AddressForm.reset();
                    if(res.success){
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: res.message
                        })
                        this.addressModal = false;
                        this.editbutton=false;
                        this.getAddressList();
                    }else{
                        this.errors = true;
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: res.message
                        }) 
                    }
                    
                }, (err: any) => {
                    this.errors = true;
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: err
                    })
                });
            }
    });
}

    async AddressSubmit(formData) {
        const params = {
            'id':formData.id,
            'type': formData.type,
            'contact_name': formData.contact_name,
            'pin_code': formData.pin_code,
            'contact_number': formData.contact_number,
            'alternate_number': formData.alternate_number,
            'address': formData.address,
            'landmark': formData.landmark,
            'country': formData.country,
            'state': formData.state,
            'is_default': formData.is_default?'Yes':'No',
        }
        Swal.fire({
            icon: 'info',
            title: 'Add Address',
            showCancelButton: true,
            html: 'Do you want to add this address ?',
            confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                this.GroupSettingsService.addAddress(params)
                .subscribe((res: any) => {
                    this.AddressForm.reset();
                    if(res.success){
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: res.message
                        })
                        this.addressModal = false;
                        this.editbutton=false;
                        this.getAddressList();
                    }else{
                        this.errors = true;
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: res.message
                        }) 
                    }
                    
                }, (err: any) => {
                    this.errors = true;
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: err
                    })
                });
            }
    });

    }

    deleteAddress(id) {

        this.confirmationService.confirm({
            message: 'Do you want to delete this history',
            header: 'Delete Route History Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                return this.GroupSettingsService.deleteAddress(id).subscribe((res) => {
                    if (res.success) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: res.message
                        })
                        this.getAddressList();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: res.message
                        }) 
                        this.getAddressList();
                    }
                }), err => {
                    this.getAddressList();
                };

            },
            reject: () => {}
        });

    }
    
}
