import { Component, OnInit , Input } from '@angular/core';
import Swal from 'sweetalert2';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {ProfileSetupService} from './profilesetup.service';
import {Router,ActivatedRoute} from '@angular/router';
import { generalHelper } from '../../helpers/generalHelper';
import { AuthService } from 'app/services/auth.service';
@Component({
    selector: 'app-profilesetup',
    templateUrl: './profilesetup.component.html',
    styleUrls: ['./profilesetup.component.scss']
})
export class ProfileSetupComponent implements OnInit {
    generalHelper = generalHelper;
    @Input()
    test: Date = new Date();
    focus;
    focus1;
    UserSelectionType: any;
    BusinessSelectionType: any;
    travelorSetup = false;
    vendorSetup = false;
    BlogingYes = false;
    BlogingOption = false;
    BusinessTypeList: any = [];
    CategoriesList: any = [];
    SubCategories: any = [];
    ProfileSetupForm: FormGroup;
    planstype: any;
    choosePlan = false;
    catID: any;
    subCatID: any;
    planList: any = [];
    asterriskmarkError: String = '';
    invalidErroruserType: String = '';
    invalidErrortravelType: String = '';
    invalidErrorisBlogger: String = '';
    invalidErrorbloggerType: String = '';
    invalidErrorbusinessName: String = '';
    invalidErrordesignation: String = '';
    invalidErrorbusinessContact: String = '';
    invalidErrorbusinessAddress: String = '';
    invalidErrorbusinessCategory: String = '';
    invalidErrorbusinessSubcategory: String = '';
    invalidErrorPlans: String = '';
    selectedplanId:any = '';
    verificationID: any;
    startAutoSync: any = '';
    isMobileVerify: boolean = false;
    userData :any;
    planBtn:any = 'btn pricing-plan-purchase-btn';
    loginUrl:boolean = ((this.router.url.split("/"))[3] == 'setup');
    constructor(private fb: FormBuilder, public http: HttpClient, private Service: ProfileSetupService, private router: Router,private route: ActivatedRoute,public authService: AuthService) { }
    ngOnInit() {
        this.verificationID = this.route.snapshot.params.verificationID;
        this.userData = this.authService.getLoggedInUser();
        this.removeHeadFoot();
        this.formValidation();
        this.plansTab(1);
        //this.emailMobileVerify();
    }

    removeHeadFoot(){
        const navbar = document.getElementsByTagName('app-navbar')[0].children[0];
        navbar.classList.add('navbar-hidden');
        const footer = document.getElementsByTagName('app-footer')[0].children[0];
        footer.classList.add('navbar-hidden');
        // localStorage.getItem('is_mobile_verified') != '1' ? this.otpVerify() : '' ;
        if(this.loginUrl){
            const footer1 = document.getElementsByTagName('app-footer')[0].children[0];
            footer1.classList.add('remove-footer-mobile');
            const footer2 = document.getElementsByTagName('footer')[0].children[0];
            footer2.classList.add('remove-footer-mobile');}
    }

    formValidation(){
        this.ProfileSetupForm = this.fb.group({
            userType: ['', [Validators.required]],
            travelType: ['Solo',  [Validators.required]],
            isBlogger: ['', [Validators.required]],
            bloggerType: [''],
            businessName: [''],
            designation: [''],
            businessEmail: [''],
            businessContact: [''],
            businessAddress: [''],
            businessGST: [''],
            businessPAN: [''],
            businessCategory: [''],
            businessSubcategory: [''],
            reffered_by: [''],

        });
    }
    get userType(){
        return this.ProfileSetupForm.controls.userType;
    }
    get travelType(){
        return this.ProfileSetupForm.controls.travelType;
    }
    get isBlogger(){
        return this.ProfileSetupForm.controls.isBlogger;
    }
    get bloggerType(){
        return this.ProfileSetupForm.controls.bloggerType;
    }
    get businessName(){
        return this.ProfileSetupForm.controls.businessName;
    }
    get designation(){
        return this.ProfileSetupForm.controls.designation;
    }
    get businessEmail(){
        return this.ProfileSetupForm.controls.businessEmail;
    }
    get businessContact(){
        return this.ProfileSetupForm.controls.businessContact;
    }
    get businessAddress(){
        return this.ProfileSetupForm.controls.businessAddress;
    }
    get businessGST(){
        return this.ProfileSetupForm.controls.businessGST;
    }
    get businessPAN(){
        return this.ProfileSetupForm.controls.businessPAN;
    }
    get businessCategory(){
        return this.ProfileSetupForm.controls.businessCategory;
    }
    get businessSubcategory() {
        return this.ProfileSetupForm.controls.businessSubcategory;
    }
    get reffered_by() {
        return this.ProfileSetupForm.controls.reffered_by;
    }

    profileSetUp(formData) {
        if (this.UserSelectionType == '3') {
            formData.planId = this.selectedplanId;
            if (formData.planId == '') {
                this.invalidErrorPlans = 'Please Choose a plan.';
                return false;
            }
        }
        if(formData.userType=='2'){
            formData.userType=2;
        }
        if(formData.userType=='3'){
            formData.userType=3;
        }
        if(!this.BlogingOption){
            formData.bloggerType='No'
        }
        this.Service.profileSetup(formData).subscribe( result => {
            if (result.success){
                Swal.fire('Thank you', result.message, 'success');
                window.location.href = '/solo/profile/edit';
            } else {
                Swal.fire('Error occurred', result.message, 'error');
            }
        });
    }

    filterTab(tabValue) {

        this.UserSelectionType = tabValue;
            if (this.UserSelectionType == '2') {
                this.travelorSetup = true;
                this.vendorSetup = false;
            }
            if (this.UserSelectionType == '3') {
                this.travelorSetup = false;
                this.vendorSetup = true;
            }
    }
    soloTab(tabValue) {
        this.BlogingYes = tabValue == 'Solo';
    }
    blogingOption(tabValue) {
        this.BlogingOption = tabValue == 'Yes';

    }
    async BusinessType() {
        this.BusinessTypeList = [];
        await this.Service.getBusinessType().then((res) => {
            if (res.success) {
                this.CategoriesList = res.data;
                this.BusinessTypeList = res.data.filter(cat => cat.parent_id == 0);
            } else {
                this.BusinessTypeList = [];
            }
        }).catch((back) => {
        });
    }

    businesstypTab(idx) {
        this.catID=idx;
        this.SubCategories= this.CategoriesList.filter(cat => cat.parent_id == this.catID);
        this.choosePlan= true;
    }
    businessSubcat(id) {
        this.subCatID = id;
    }
    emailMobileVerify(){
        let count = 0;
        this.startAutoSync = setInterval(() => {
            count = count + 1;
            if (localStorage.getItem('is_mobile_verified') == '0') {
                this.Service.authVerification({user: this.verificationID}).subscribe(result => {
                    if (result.success) {
                        if (result.data.status == 'email_verified') {
                            if (count == 1) {
                                localStorage.setItem('is_mobile_verified', '0');
                                localStorage.setItem('access_token', result.data.user.access_token);
                                localStorage.setItem('user', JSON.stringify(result.data.user));
                                this.otpVerify();
                            }
                        } else if (result.data.status == 'verified') {
                            localStorage.setItem('is_mobile_verified', '1');
                            localStorage.setItem('access_token', result.data.user.access_token);
                            localStorage.setItem('user', JSON.stringify(result.data.user));
                            this.isMobileVerify = true;
                            // Swal.close();
                        }
                    }
                });
            }
        }, 3000);
    }
    async otpVerify(){
        Swal.fire({
            title: 'Welcome to TRAVWIKI!',
            text: 'Please type here your OTP',
            // timer: 10000,
            input: 'text',
            inputPlaceholder: '124563',
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText:'Resend OTP',
            allowOutsideClick: false,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Verify OTP',
            // html:
            //     `<b style="text-decoration:underline;"><a href="javascript:void(0)" (click)="resendOtp()">Resend OTP</a> </b>`,

        }).then(async (res) => {
            if (res.isConfirmed){
                if (!res.value) {
                   await this.getOtpPopup();
                } else {
                    await this.Service.otpVerify({code:res.value}).subscribe( result => {
                        if (result.success) {
                            localStorage.setItem('is_mobile_verified', '1');
                            Swal.fire('Thank you', result.message, 'success');
                            this.BusinessType();
                        } else {
                            this.getOtpPopup();
                        }
                    });
                }
            }else{
                const params = {'attempt' : '0'}
                this.Service.resendOtp(params).subscribe((res: any) => {
                    Swal.fire('Resend OTP','OTP Sent on your mobile').then((cb) => {
                        if (cb){
                            this.otpVerify();
                        }
                    });
                });
            }
        });
    }

    // resendOtp(){
    //     console.log('fnjnjn');
    //     const params = {'attempt' : '0'}
    //     this.Service.resendOtp(params).subscribe((res: any) => {
    //     })
    // }

    async plansTab(panel) {
        this.planstype = panel;
    }

    async selectedPlan(Id) {
        this.planBtn = 'btn btn-success';
        this.selectedplanId = Id;
    }
    
    async Plans() {
        this.planList = [];
        await this.Service.getPlans(this.catID ,this.subCatID).then((res) => {
            if (res.success) {
                this.planList = res.data;
            } else {
                this.planList = [];
            }
        }).catch((back) => {
        });
    }

    validateProfileFormData(formData){
        formData.travelType='Solo'
        if (this.UserSelectionType === '2') {
            // if (formData.travelType.length === 0) {
            //     this.invalidErrortravelType = 'This Field is required please fill this';
            //     this.plansTab(1);
            // }
            if(this.BlogingOption){
                if (formData.isBlogger.length === 0) {
                    this.invalidErrorisBlogger = 'This Field is required please fill this';
                    this.plansTab(1);
                }
                if (formData.bloggerType.length === 0) {
                    this.invalidErrorbloggerType = 'This Field is required please fill this';
                    this.plansTab(1);
                }

                if(formData.bloggerType.length === 0 || formData.bloggerType.length === 0 || formData.isBlogger.length === 0){
                    this.asterriskmarkError = 'All ( * ) mark field is required please fill All';
                    return false;
                }
            }

        }
        if (this.UserSelectionType === '3') {
            if (formData.userType.length === 0) {
                this.invalidErroruserType = 'Please Choose User Type.';
                this.plansTab(1);
            }
            if (formData.businessName.length === 0) {
                this.invalidErrorbusinessName = 'This Field is required please fill this';
                this.plansTab(1);
            }
            if (formData.designation.length === 0) {
                this.invalidErrordesignation = 'This Field is required please fill this';
                this.plansTab(1);
            }
            if (formData.businessContact.length === 0) {
                this.invalidErrorbusinessContact = 'This Field is required please fill this';
                this.plansTab(1);
            }
            if (formData.businessAddress.length === 0) {
                this.invalidErrorbusinessAddress = 'This Field is required please fill this';
                this.plansTab(1);
            }
            if (formData.businessCategory.length === 0) {
                this.invalidErrorbusinessCategory = 'This Field is required please fill this.';
                this.plansTab(1);
            }
            if (formData.businessSubcategory.length === 0) {
                this.invalidErrorbusinessSubcategory = 'This Field is required please fill this';
                this.plansTab(1);
            }
            // tslint:disable-next-line:max-line-length
            if(formData.userType.length === 0 || formData.businessName.length === 0 || formData.designation.length === 0 || formData.businessContact.length === 0 || formData.businessAddress.length === 0 || formData.businessCategory.length === 0 || formData.businessSubcategory.length === 0){
                this.asterriskmarkError = 'All ( * ) mark field is required please fill All';
                return false;
            }
        }
        this.Plans();
        this.plansTab(2);
    }

    async getOtpPopup() {
        if ( localStorage.getItem('is_mobile_verified') != '1') {
            Swal.fire('Oooppppsss!', 'Invalid OTP', 'error').then((cb) => {
                if (cb){
                    this.otpVerify();
                }
                
            });
        }
    }
}
