import { Component, OnInit } from '@angular/core';
import { AddStatusService } from './add_status.service';

@Component({
    selector: 'app-add_status',
    templateUrl: './add_status.component.html',
    styleUrls: ['./add_status.component.scss']
})

export class AddStatusComponent implements OnInit {
    user: any = {};
    loading: boolean;
    errors: boolean;
    constructor(private AddStatusService: AddStatusService) { }

    ngOnInit() {
        this.getUserProfile();
    }

    getUserProfile() {
        this.AddStatusService.profile().toPromise().then(res => {
            this.user = res.data;
        }).catch(e => {});
    }
}
