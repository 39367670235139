import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {ProfileSetupComponent} from './profilesetup/profilesetup.component';
import { EditProfileComponent } from './profile/editprofile/editprofile.component';
import { SettingsComponent } from './profile/settings/settings.component';
import { SoloRiderProfileComponent } from './profile/soloriderprofile/soloriderprofile.component';
import { SoloRiderDashboardComponent } from './profile/solorider_dashboard/solorider_dashboard.component';
import { AddSoloTravelHistoryComponent } from './travel_history/solo/add/add_solo_travelhistory.component';
import { CreateTravelHistoryComponent } from "./travel_history/solo/create/create.component";
import { ViewTravelHistoryComponent } from "./travel_history/solo/view/view.component";
import { CreateRoutesComponent } from "./routes/solo/create/create.component";
import { ViewRoutesComponent } from "./routes/solo/view/view.component";
import { SoloTravelHistoryListingComponent } from './travel_history/solo/listing/solotravelhistorylisting.component';
import {EditSoloTravelHistoryComponent} from './travel_history/solo/edit/edit.component';
import {soloTravelHistoryService } from './travel_history/solo/soloTravelHistory.service';
import { NewPostComponent } from './new_post/new_post.component';
import { AddBlogComponent } from './Blog/add_blog/add_blog.component';

import { BlogListingComponent } from './Blog/blog_listing/blog_listing.component';
import { BlogViewComponent } from './Blog/blog_view/blog_view.component';




import { FollowersComponent } from './followers/followers.component';
import { SoloRoutesListingComponent } from './routes/solo/solo_routes_listing/solo_routes_listing.component';
import { SoloRoutesAddComponent } from './routes/solo/solo_routes_add/solo_routes_add.component';
import { EditSoloRouteComponent } from './routes/solo/edit/edit.component';
import {SoloRoutesTravelHistoryService } from './routes/solo/solo_routes_travelhistory.service';
import { StatusComponent } from './status/status.component';
import { ReferralComponent } from './referral/referral.component';
import { GroupMembersComponent } from './group/members/members.component';
import { GroupCreationComponent } from './group/create/create.component';
import { GroupSidebarComponent } from './group/sidebar/sidebar.component';
import { GroupSidebarService } from './group/sidebar/sidebar.service';
import { EditComponent } from './group/edit/edit.component';
import { TravelPlanComponent } from './group/travelplan/listing/grouptravelhistorylisting.component';
import {UserGroupProfileComponent} from './user_groupprofile/user_groupprofile.component';
import { GroupSettingsComponent } from './group/settings/groupsettings.component';
import { GalleryComponent } from './group/gallery/gallery.component';

import { AddBrandComponent } from './brand/add_brand/add_brand.component';
import { AddStatusComponent } from './add_status/add_status.component';
import { SendMessageComponent } from './send_message/send_message.component';
import { ProfileComponent } from './profile/profile.component';

import { AuthGuardService } from '../services/auth-guard.service';
import { PagesComponent } from './pages.component';
import { RouterModule, Routes } from '@angular/router';
import {ProfileSetupService} from './profilesetup/profilesetup.service';
import {UserProfileComponent} from './user_profile/user_profile.component';
import {UserProfileService} from './user_profile/user_profile.service';
import {EditProfileService } from './profile/editprofile/editprofile.service';

import {SettingsService } from './profile/settings/settings.service';
import {SoloRiderProfileService } from './profile/soloriderprofile/soloriderprofile.service';
import {SoloRiderDashboardService } from './profile/solorider_dashboard/solorider_dashboard.service';
import {NewPostService } from './new_post/new_post.service';
import {BlogService} from "./Blog/blog.service";
import {BlogListingService } from './Blog/blog_listing/blog_listing.service';
import { BlogViewService } from './Blog/blog_view/blog_view.service';

import { UploadComponent } from 'app/website/news/upload/upload.component';
import { ViewComponent } from 'app/website/news/view/view.component';
import { NewsListingComponent } from 'app/website/news/listing/listing.component';
import { NewsService } from 'app/website/news/news.service';

import {FollowersService } from './followers/followers.service';
import {StatusService } from './status/status.service';
import {ReferralService } from './referral/referral.service';
import {GroupMembersService } from './group/members/members.service';
import {GroupCreationService } from './group/create/create.service';
import {UserGroupProfileService} from './user_groupprofile/user_groupprofile.service';
import {GroupSettingsService } from './group/settings/groupsettings.service';
import {GalleryService } from './group/gallery/gallery.service';
import {EditService } from './group/edit/edit.service';
import {TravelPlanService } from './group/travelplan/groupTravelHistory.service';
import { CreateTravelPlanComponent } from './group/travelplan/create/create.component';
import {AddBrandService } from './brand/add_brand/add_brand.service';
import { ListBrandComponent } from './brand/list/list.component';
import { ListBrandService } from './brand/list/list.service';
import {AddStatusService } from './add_status/add_status.service';
import {SendMessageService } from './send_message/send_message.service';
import {NavbarComponent} from '../shared/navbar/navbar.component';
import {FooterComponent} from '../shared/footer/footer.component';
import { DialogModule } from 'primeng/dialog';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import {ToastModule} from 'primeng/toast';
import {FieldsetModule} from 'primeng/fieldset';
import {AccordionModule} from 'primeng/accordion';
import { MessageService } from 'primeng/api';
import {DropdownModule} from 'primeng/dropdown';
import {TooltipModule} from 'primeng/tooltip';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ProgressBarModule} from 'primeng/progressbar';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {PaginatorModule} from 'primeng/paginator';
import {GMapModule} from 'primeng/gmap';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import {StepsModule} from 'primeng/steps';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {InputSwitchModule} from 'primeng/inputswitch';
import {CalendarModule} from 'primeng/calendar';
import {TabViewModule} from 'primeng/tabview';
import {MultiSelectModule} from 'primeng/multiselect';
import {ChipsModule} from 'primeng/chips';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import {SidebarService } from './sidebar/sidebar.service';
import {SidebarComponent } from './sidebar/sidebar.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import {ImageCropperModule} from "ngx-image-cropper";
import {FileUploadModule} from "primeng/fileupload";
import {OtherUserTravelHistoryService} from "./user_profile/travel_history/OtherUserTravelHistory.service";
import {OtherUserRoutesService} from "./user_profile/routes/OtherUserRoutes.service";
import { OtherUserRoutesListingComponent } from './user_profile/routes/listing/listing.component';
import {ViewOtherUserRoutesComponent} from "./user_profile/routes/view/view.component";
import {OtherUserTravelHistoryListingComponent} from "./user_profile/travel_history/listing/listing.component";
import { OtherUserBlogListingComponent } from './user_profile/Blog/blog_listing/blog_listing.component';
import {ViewOtherUserTravelHistoryComponent} from "./user_profile/travel_history/view/view.component";
import {UserSidebarComponent} from "./user_profile/user_sidebar/user-sidebar.component";
import {UserCommonProfileComponent} from "./user_profile/user-common-profile/common-profile.component";
import {OtherUserFollowersComponent} from "./user_profile/followers/listing/listing.component"
import {EditorModule} from 'primeng/editor';
import {KeyFilterModule} from 'primeng/keyfilter';
import {DateAgoPipe} from "../pipes/date-ago.pipe";
import {GalleriaModule} from 'primeng/galleria';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { GallerizeModule } from '@ngx-gallery/gallerize';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { NgImageSliderModule } from 'ng-image-slider';
import { AngularEditorModule } from '@kolkov/angular-editor';
import {PageLeftsidebarComponent} from "./sidebar/leftsidebar/leftsidebar.component";
import {PageLeftSidebarService} from "./sidebar/leftsidebar/leftsidebar.service";
import { ViewTravelPlanComponent } from './group/travelplan/view/view.component';
import { GroupDashboardComponent } from './group/profile/dashboard/group_dashboard.component';
import { GroupsDashboardService } from './group/profile/dashboard/group_dashboard.service';
import { GroupFollowersComponent } from './group/followers/followers.component';
import { GroupFollowersService } from './group/followers/followers.service';
import { GroupProfileComponent } from './group/profile/profile.component';
import { GroupProfileService } from './group/profile/profile.service';
import { PostGroupProfileComponent } from './group/profile/profile/groupprofile.component';
import { PostGroupProfileService } from './group/profile/profile/groupprofile.service';
import { groupNewPostComponent } from './group/new_post/new_post.component';
import { groupNewPostService } from './group/new_post/new_post.service';
const routes: Routes = [
  { path: '', component: PagesComponent,
    children: [
      {path: 'solo/profile/setup/:verificationID', component: ProfileSetupComponent, canActivate: [AuthGuardService]},
      {path: 'solo/profile', component: SoloRiderProfileComponent , canActivate: [AuthGuardService]},
      {path: 'solo/profile/edit', component: EditProfileComponent,canActivate: [AuthGuardService]},
      {path: 'solo/profile/settings', component: SettingsComponent,canActivate: [AuthGuardService]},
      {path: 'solo/dashboard', component: SoloRiderDashboardComponent,canActivate: [AuthGuardService]},
      {path: 'solo/post/create', component: NewPostComponent,canActivate: [AuthGuardService]},
      {path: 'solo/post/edit/:id', component: NewPostComponent,canActivate: [AuthGuardService]},
      {path: 'solo/blogs', component: BlogListingComponent, canActivate: [AuthGuardService]},
      {path: 'solo/blog/add', component: AddBlogComponent, canActivate: [AuthGuardService]},
      {path: 'solo/blog/edit/:id', component: AddBlogComponent, canActivate: [AuthGuardService]},
      {path: 'solo/blog/detail/:id', component: BlogViewComponent, canActivate: [AuthGuardService]},
      {path: 'solo/news/list', component: NewsListingComponent, canActivate: [AuthGuardService]},
      {path: 'solo/news/add', component: UploadComponent, canActivate: [AuthGuardService]},
      {path: 'solo/news/edit/:id', component: UploadComponent, canActivate: [AuthGuardService]},
      {path: 'solo/news/view/:id', component: ViewComponent, canActivate: [AuthGuardService]},
      {path: 'solo/routes', component: SoloRoutesListingComponent, canActivate: [AuthGuardService]},
      {path: 'solo/routes/add', component: SoloRoutesAddComponent, canActivate: [AuthGuardService]},
      {path: 'solo/routes/edit/:id', component: CreateRoutesComponent, canActivate: [AuthGuardService]},
      {path: 'solo/routes/view/:id', component: ViewRoutesComponent, canActivate: [AuthGuardService]},
      {path: 'solo/routes/create', component: CreateRoutesComponent, canActivate: [AuthGuardService]},
      {path: 'solo/travel-history', component: SoloTravelHistoryListingComponent, canActivate: [AuthGuardService]},
      {path: 'solo/travel-history/create', component: CreateTravelHistoryComponent, canActivate: [AuthGuardService]},
      {path: 'solo/travel-history/view/:id', component: ViewTravelHistoryComponent, canActivate: [AuthGuardService]},
      {path: 'solo/travel-history/add', component: AddSoloTravelHistoryComponent, canActivate: [AuthGuardService]},
      {path: 'solo/travel-history/edit/:id', component: CreateTravelHistoryComponent, canActivate: [AuthGuardService]},
      {path: 'userprofile/:username', component: UserProfileComponent, canActivate: [AuthGuardService]},
      {path: 'solo/status', component: StatusComponent, canActivate: [AuthGuardService]},
      {path: 'solo/referral', component: ReferralComponent, canActivate: [AuthGuardService]},
      {path: 'solo/add_brand', component: AddBrandComponent, canActivate: [AuthGuardService]},
      {path: 'solo/edit_brand/:id', component: AddBrandComponent, canActivate: [AuthGuardService]},
      {path: 'solo/list_brand', component: ListBrandComponent, canActivate: [AuthGuardService]},
      {path: 'solo/status/add', component: AddStatusComponent, canActivate: [AuthGuardService]},
      {path: 'solo/messages/send',  component: SendMessageComponent, canActivate: [AuthGuardService]},
      {path: 'solo/followers', component: FollowersComponent, canActivate: [AuthGuardService]},
      {path: 'solo/sidebar', component: SidebarComponent,canActivate: [AuthGuardService]},
      {path: 'common/profile', component: ProfileComponent, canActivate: [AuthGuardService]},

      // all group page path start here !
      {path: 'group/members/profile', component: GroupMembersComponent, canActivate: [AuthGuardService]},
      {path: 'group/dashboard/:uuid', component: GroupDashboardComponent, canActivate: [AuthGuardService]},
      {path: 'group/profile/:uniqueName', component: PostGroupProfileComponent, canActivate: [AuthGuardService]},
      {path: 'group/creation', component: GroupCreationComponent, canActivate: [AuthGuardService]},
      {path: 'group/edit', component: EditComponent, canActivate: [AuthGuardService]},
      {path: 'group/travelplan/:groupId', component: TravelPlanComponent, canActivate: [AuthGuardService]},
      {path: 'group/viewtravelplan/:editId/:groupId', component: ViewTravelPlanComponent, canActivate: [AuthGuardService]},
      {path: 'group/createtravelplan/:groupId', component: CreateTravelPlanComponent, canActivate: [AuthGuardService]},
      {path: 'group/edittravelplan/:editId/:groupId', component: CreateTravelPlanComponent, canActivate: [AuthGuardService]},
      {path: 'usergroupprofile/:username', component: UserGroupProfileComponent, canActivate: [AuthGuardService]},
      {path: 'group/settings', component: GroupSettingsComponent, canActivate: [AuthGuardService]},
      {path: 'group/gallery', component: GalleryComponent, canActivate: [AuthGuardService]},
      {path: 'group/followers/:groupId', component: GroupFollowersComponent, canActivate: [AuthGuardService]},
      {path: 'group/post/:groupId/:groupUuid', component: groupNewPostComponent, canActivate: [AuthGuardService]},
      {path: 'group/post/:postId/:groupId/:groupUuid', component: groupNewPostComponent, canActivate: [AuthGuardService]},


      // common user and path start here !
      {path: 'Users/TravelHistory/:username', component: OtherUserTravelHistoryListingComponent,canActivate: [AuthGuardService]},
      {path: 'Users/Blogs/:username', component: OtherUserBlogListingComponent,canActivate: [AuthGuardService]},
      {path: 'Users/TravelHistoryView', component: ViewOtherUserTravelHistoryComponent,canActivate: [AuthGuardService]},
      {path: 'Users/Routes/:username', component: OtherUserRoutesListingComponent,canActivate: [AuthGuardService]},
      {path: 'Users/RoutesView', component: ViewOtherUserRoutesComponent,canActivate: [AuthGuardService]},
      {path: 'Users/Sidebar', component: UserSidebarComponent,canActivate: [AuthGuardService]},
      {path: 'Users/Common', component: UserCommonProfileComponent,canActivate: [AuthGuardService]},
      {path: 'Users/followers/:username', component: OtherUserFollowersComponent,canActivate: [AuthGuardService]},
    ]
  }
];


@NgModule({
  declarations: [PagesComponent,
    ProfileComponent,
    ProfileSetupComponent,
    EditProfileComponent,
    SettingsComponent,
    SoloRiderProfileComponent,
    AddSoloTravelHistoryComponent,
    EditSoloTravelHistoryComponent,
    NewPostComponent,
    AddBlogComponent,
    BlogListingComponent,
    BlogViewComponent,
    FollowersComponent,
    SoloRoutesListingComponent,
    SoloRoutesAddComponent,
    EditSoloRouteComponent,
    SoloTravelHistoryListingComponent,
    CreateTravelHistoryComponent,
    ViewTravelHistoryComponent,
    CreateRoutesComponent,
    ViewRoutesComponent,
    StatusComponent,
    ReferralComponent,
    GroupMembersComponent,
    GroupCreationComponent,
    EditComponent,
    TravelPlanComponent,
    CreateTravelPlanComponent,
    ViewTravelPlanComponent,
    UserGroupProfileComponent,
    GroupSettingsComponent,
    GalleryComponent,
    AddBrandComponent,
    ListBrandComponent,
    AddStatusComponent,
    SendMessageComponent,
    NavbarComponent,
    FooterComponent,
    SidebarComponent,
    SoloRiderDashboardComponent,
    UserProfileComponent,
    OtherUserRoutesListingComponent,
    ViewOtherUserRoutesComponent,
    ViewOtherUserTravelHistoryComponent,
    OtherUserTravelHistoryListingComponent,
    OtherUserBlogListingComponent,
    UserCommonProfileComponent,
    UserSidebarComponent,
    OtherUserFollowersComponent,
    DateAgoPipe,
    UploadComponent,
    ViewComponent,
    NewsListingComponent,
    PageLeftsidebarComponent,
    GroupSidebarComponent,
    GroupDashboardComponent,
    GroupFollowersComponent,
    GroupProfileComponent,
    PostGroupProfileComponent,
    groupNewPostComponent
  ],
  imports: [
    CommonModule,
    DialogModule,
    ScrollPanelModule,
    InputSwitchModule,
    PaginatorModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    DropdownModule,
    RouterModule.forChild(routes),
    DialogModule,
    FieldsetModule,
    AccordionModule,
    ToastModule,
    ScrollPanelModule,
    TooltipModule,
    AutoCompleteModule,
    GMapModule,
    ConfirmDialogModule,
    StepsModule,
    OverlayPanelModule,
    CalendarModule,
    TabViewModule,
    MultiSelectModule,
    ChipsModule,
    RouterModule.forChild(routes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    IvyCarouselModule,
    NgxDocViewerModule,
    ImageCropperModule,
    FileUploadModule,
    EditorModule,
    KeyFilterModule,
    GalleriaModule,
    GalleryModule,
    GallerizeModule,
    LightboxModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareIconsModule,
    NgImageSliderModule,
    AngularEditorModule
  ],
  exports: [RouterModule],
  providers: [
    ProfileSetupService,
    EditProfileService,
    SettingsService,
    SoloRiderProfileService,
    SoloRiderDashboardService,
    NewPostService,
    BlogService,
    BlogListingService,
    BlogViewService,
    FollowersService,
    SoloRoutesTravelHistoryService,
    soloTravelHistoryService,
    OtherUserRoutesService,
    OtherUserTravelHistoryService,
    StatusService,
    ReferralService,
    GroupMembersService,
    GroupCreationService,
    EditService,
    TravelPlanService,
    UserGroupProfileService,
    AddBrandService,
    ListBrandService,
    AddStatusService,
    SendMessageService,
    ConfirmationService,
    MessageService,
    SidebarService,
    UserProfileService,
    GroupSettingsService,
    GalleryService,
    NewsService,
    PageLeftSidebarService,
    GroupSidebarService,
    GroupsDashboardService,
    GroupFollowersService,
    GroupProfileService,
    PostGroupProfileService,
    groupNewPostService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PagesModule { }
