import { Injectable } from '@angular/core';
import { BaseService } from '../../_services/base.service';

@Injectable()
export class ProfileSetupService {

    constructor(private baseService: BaseService) { }

    profileSetup(data) {
        return this.baseService.post(`user/update`, data);
    }
    getBusinessType() {
        return this.baseService.getRequest(`categories/0`).toPromise();
    }
    getPlans(cat_id, sub_cat_id) {
        return this.baseService.getRequest(`plans/${cat_id}/${sub_cat_id}`).toPromise();
    }
    emailVerification(params) {
        return this.baseService.post(`auth/verify/email`, params);
    }
    otpVerify(params) {
        return this.baseService.post(`verify/otp`, params);
    }
    resendOtp(params) {
        return this.baseService.post(`user/resend_otp`, params);
    }
    forgotPassword(params) {
        return this.baseService.post(`auth/forgot-password`, params);
    }
    resetPassword(params) {
        return this.baseService.post(`auth/password-reset`, params);
    }
    authVerification(params) {
        return this.baseService.post(`auth/is-verified`, params);
    }
    resendEmail(params) {
        return this.baseService.post(`auth/resend-email`, params);
    }
}
