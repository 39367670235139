import { Component, OnInit } from '@angular/core';
import { groupNewPostService } from './new_post.service';
import { generalHelper } from '../../../helpers/generalHelper';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import Swal from "sweetalert2";
import {SelectItem} from 'primeng/api';
import {HttpEventType} from "@angular/common/http";
import {ActivatedRoute, Router} from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { environment } from 'enviornments/environment';
@Component({
    selector: 'app-group-post',
    templateUrl: './new_post.component.html',
    styleUrls: ['./new_post.component.scss']
})

export class groupNewPostComponent implements OnInit {
    env=environment;
    generalHelper = generalHelper;
    user: any = {};
    newPostForm : FormGroup;
    loading: boolean;
    errors: boolean;
    Pricing: any;
    Price= false;
    image: File = null;
    video: File = null;
    document: File = null;
    uploadStatus: number;
    ImageURL:any=[];
    images:any=[];
    AudioURL:any;
    DocumentURL:any;
    VideoURL:any;
    slides : any = [];
    htmlContent = '';
    credfileName:any;
    credFile: any;
    config: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '15rem',
        minHeight: '5rem',
        placeholder: 'Enter text here...',
        translate: 'no',
        defaultParagraphSeparator: 'p',
        defaultFontName: 'Arial',
        toolbarHiddenButtons: [['bold', 'insertVideo']],
        customClasses: [
          {
            name: 'quote',
            class: 'quote',
          },
          {
            name: 'redText',
            class: 'redText',
          },
          {
            name: 'titleText',
            class: 'titleText',
            tag: 'h1',
          },
        ],
      };
      contentLoader:boolean=false;
      editPostAttachmentURL:any=[];
      editPostImgeAttachmentURL:any=[];
      editPostVideoAttachmentURL:any=[];
      editPostDocAttachmentURL:any=[];
      PostType:any;
      attachmentHelper:any;
      selectedUsers: any = [];
      usersList: any = [];
      Taguser:any;
    constructor(private postService: groupNewPostService, public fb: FormBuilder, private router: Router, private route: ActivatedRoute) { }

    ngOnInit() {
        this.newPostInit();
        this.route.snapshot.params.postId ? this.editPost(this.route.snapshot.params.postId) : '';
        
    }


newPostInit() {
        this.newPostForm = this.fb.group({
            id: [0],
            type: ['Image'],
            title: ['', [Validators.required]],
            description: [''],
            privacy: ['Public', [Validators.required]],
            sharing: ['Yes', [Validators.required]],
            tagged_users: ['No'],
        });

        this.PostTypeVal('Image');
        
    }

    

    get id() {
        return this.newPostForm.controls.id
    }
    // get type() {
    //     return this.newPostForm.controls.type
    // }
    get title() {
        return this.newPostForm.controls.title
    }
    get description() {
        return this.newPostForm.controls.description
    }
    get privacy() {
        return this.newPostForm.controls.privacy
    }
    get sharing() {
        return this.newPostForm.controls.sharing
    }
    get tagged_users() {
        return this.newPostForm.controls.tagged_users
    }

    
    filterTab(tabValue) {

        this.Pricing = tabValue;
            if (this.Pricing == 'Charge') {
                this.Price = true;
            }  
           else {
               this.Price= false;
           }
            
    }

    handleVideoInput(e) {
        if (e.target.files && e.target.files.length) {
            this.video = <File>e.target.files[0];
        }
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]); 
        reader.onload = (_event) => { 
        this.VideoURL = reader.result; 
        // this.ImageURL=[];
        // this.DocumentURL=null;
        }
    }

    handleImageInput(e) {

        this.images = [];
        if (e.target.files && e.target.files.length) {
            let slides = [];
            for (var i = 0; i < e.target.files.length; i++) {
                if (this.images.length == 5) {
                    Swal.fire('Limit Exceeded','You are not eligible to upload more then 5 Images','error');
                    return false;
                }
                this.images.push(e.target.files[i]);
                let reader = new FileReader();
                reader.onload = function(el) {
                    slides.push(el.target.result);
                }
                reader.readAsDataURL(e.target.files[i]);
            }
            this.ImageURL = slides;

        }
        
        // this.VideoURL=null;
        // this.DocumentURL=null;
    
    }

    handleImageInputMore(e) {

        let self =this;
        if (e.target.files && e.target.files.length) {
            for (var i = 0; i < e.target.files.length; i++) {
                if (this.images.length == 5) {
                    Swal.fire('Limit Exceeded','You are not eligible to upload more then 5 Images','error');
                    return false;
                }
                this.images.push(e.target.files[i]);
                let reader = new FileReader();
                reader.onload = function(el) {
                    self.ImageURL.push(el.target.result);
                }
                reader.readAsDataURL(e.target.files[i]);
            }
            // this.ImageURL = slides;

        }
    
    }

    removeImage(index: number) {
        this.ImageURL.splice(index, 1);
        this.images.splice(index, 1);
      }

    PostTypeVal(val){
        this.PostType=val;
    }
    handleDocInput(e) {
        if (e.target.files && e.target.files.length) {
            this.readCredFile(e);
            this.document = <File>e.target.files[0];
        }
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]); 
        reader.onload = (_event) => { 
        this.DocumentURL = reader.result; 
        // this.VideoURL=null;
        // this.ImageURL=[];
        }
    }


    readCredFile(event: any): void {
        if (event.target.files && event.target.files[0]) {
          const fileName = event.target.files[0].name;
            this.credfileName = fileName;
            this.readFileContent(event.target.files[0]).then(content => {
              this.credFile = content;
            }).catch(error => {
                console.log(error);
            });
        }
      }

      readFileContent(file) {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onload = event => resolve(reader.result);
          reader.onerror = error => reject(error);
          reader.readAsText(file);
        });
      }

    submitPost(data) {
        if(data.description==''){
            Swal.fire({
                icon: 'info',
                title: 'Add some description',
                text: 'Your post is more effective when your post has some description!',
                showCancelButton: true,
                confirmButtonText: 'Submit post',
                cancelButtonText: 'Go to post'
            }).then((result) => {
                if (result.isConfirmed) {
                    let taggedUsers = []
                    data.tagged_users.filter(el => { taggedUsers.push(el.value);});
                    
                    this.contentLoader=true;
                    const params = new FormData();
                    params.append('id', data.id);
                    params.append('group_id', this.route.snapshot.params.groupId);
                    params.append('type', 'Image');
                    params.append('title', data.title);
                    params.append('description', data.description);
                    params.append('privacy', data.privacy);
                    params.append('tagged_users', taggedUsers.toString());
                    params.append('sharing', data.sharing);
                // params.append('image', this.ImageURL[0]);
                    for (var i = 0; i < this.images.length; i++) {
                        params.append("image[]", this.images[i]);
                    }
                    params.append('video', this.video);
                    params.append('document', this.document);
                    this.postService.addUpdate(params).subscribe(event => {
                        if (event.type === HttpEventType.UploadProgress){
                            this.uploadStatus = Math.round(event.loaded / event.total * 100)
                        } else if (event.type === HttpEventType.Response){
                            this.resetPostForm();
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: event.body.message
                            });
                            this.contentLoader=false;
                            this.router.navigate(['group/dashboard/'+this.route.snapshot.params.groupUuid]);
                        }
                    }, error => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error.message
                        });
                        this.contentLoader=false;
                        this.router.navigate(['group/dashboard/'+this.route.snapshot.params.groupUuid]);
                    });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    console.log('enter');
                }
            });
        }else{
                let taggedUsers = []
                data.tagged_users.filter(el => { taggedUsers.push(el.value);});
                
                this.contentLoader=true;
                const params = new FormData();
                params.append('id', data.id);
                params.append('group_id', this.route.snapshot.params.groupUuid);
                params.append('type', 'Image');
                params.append('title', data.title);
                params.append('description', data.description);
                params.append('privacy', data.privacy);
                params.append('tagged_users', taggedUsers.toString());
                params.append('sharing', data.sharing);
            // params.append('image', this.ImageURL[0]);
                for (var i = 0; i < this.images.length; i++) {
                    params.append("image[]", this.images[i]);
                }
                params.append('video', this.video);
                params.append('document', this.document);
                this.postService.addUpdate(params).subscribe(event => {
                    if (event.type === HttpEventType.UploadProgress){
                        this.uploadStatus = Math.round(event.loaded / event.total * 100)
                    } else if (event.type === HttpEventType.Response){
                        this.resetPostForm();
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: event.body.message
                        });
                        this.contentLoader=false;
                    this.router.navigate(['group/dashboard/'+this.route.snapshot.params.groupUuid]);
                    }
                }, error => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.message
                    });
                    this.contentLoader=false;
                    this.router.navigate(['group/dashboard/'+this.route.snapshot.params.groupId]);
                });
        }
        
    }

    

    resetPostForm() {
        this.newPostForm.reset();
        this.image = null;
        this.video = null;
        this.document = null;
    }

    private editPost(id) {
        console.log('id',id)
        this.editPostAttachmentURL=[];
        this.editPostImgeAttachmentURL=[];
        this.editPostVideoAttachmentURL=[];
        this.editPostDocAttachmentURL=[];
        this.contentLoader=true;
        this.selectedUsers=[];
        this.postService.details(id).toPromise().then(res => {
            if (res.success) {
                this.selectedUsers = [];
                if(res.data.tagged_users){
                    for (const userId of res.data.tagged_users.split(',')) {
                        let selected = this.Taguser.find(el => { return el.user_id == userId});
                        this.selectedUsers.push({name:selected.name,value:selected.user_id});
                    }
                }
                
                
                this.newPostForm.patchValue({
                    id: this.route.snapshot.params.id,
                    title : res.data.title,
                    description : res.data.description,
                    privacy : res.data.privacy,
                    type : res.data.type,
                    sharing : res.data.sharing, 
                   
                    
                });
                let desc = res.data.description;
                // this.PostTypeVal(res.data.type);
                this.PostTypeVal('Image');
                this.editPostAttachmentURL = res.data.attachment;
                this.editPostImgeAttachmentURL=res.data.attachment.filter(el=> el.type=='Image');
                this.editPostVideoAttachmentURL=res.data.attachment.filter(el=> el.type=='Video');;
                this.editPostDocAttachmentURL=res.data.attachment.filter(el=> el.type=='Document');;
                this.contentLoader=false;
            
            }
        }).catch((back) => {
            this.contentLoader=false;
        });
    }


}
