import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl, FormArray } from '@angular/forms';

@Injectable()
export class ValidationsService {
  constructor() { }

  validateRange(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (control.value !== undefined && (isNaN(control.value) || control.value < min || control.value > max)) {
            return { 'ErrorRange': true };
        }
        return null;
    };
  }

  validateEmailAddress(email: string) {
    if (email !== undefined) {
      let isValid = /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,9})$/.test(email);
      if(isValid)
        return true;
      else
        return false;
    }
  }

  validatePolicyName(policyname) {
    if (policyname !== undefined) {
      return /^[A-Za-z0-9_\-\.]{3,128}$/.test(policyname);
    }
  }
  
  restrictComa(word) {
    if (word !== undefined) {
      return /^[^,]+$/.test(word);
    }
  }

  // max length
  maxLength(max: number): ValidatorFn | any {
    return (control: AbstractControl[]) => {
          if (!(control instanceof FormArray)) return;
          return control.length > max ? { maxLength: true } : null;
      }
  }

  // min length
  minLength(min: number): ValidatorFn | any {
      return (control: AbstractControl[]) => {
          if (!(control instanceof FormArray)) return;
          return control.length < min ? { minLength: true } : null;
      }
  }

  formatDate(date:string='', formatter:string='-', timeformat:boolean=false){
    let dateString;
    if(date) dateString = new Date(date);
    else  dateString =  new Date();

    let month = '' + (dateString.getMonth() + 1);
    let day   = '' + dateString.getDate();
    let year  = dateString.getFullYear();
    let hours = dateString.getHours();
		let minutes = dateString.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? '0'+hours : hours;
    minutes = minutes < 10 ? '0'+minutes : minutes;
    let strTime = '';
		if(timeformat) strTime = " " + hours + ':' + minutes+ ' ' + ampm ;

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return (formatter=='-')?([year, month, day].join(formatter)+strTime):([day, month, year].join(formatter)+strTime);
  }

  AddMinusInDate(date:string, days:number, type:string) {
    let dateFormat;
    if(date) dateFormat = this.formatDate(date);
    else dateFormat = this.formatDate();

    let parts = dateFormat.split("-");
    let dt = new Date(
        parseInt(parts[0], 10),      // year
        parseInt(parts[1], 10) - 1,  // month (starts with 0)
        parseInt(parts[2], 10)       // date
    );
    if(type=='plus')
      dt.setTime(dt.getTime() + days * 86400000);
    if(type=='minus')
      dt.setTime(dt.getTime() - days * 86400000);
    parts[0] = "" + dt.getFullYear();
    parts[1] = "" + (dt.getMonth() + 1);
    if (parts[1].length < 2) {
        parts[1] = "0" + parts[1];
    }
    parts[2] = "" + dt.getDate();
    if (parts[2].length < 2) {
        parts[2] = "0" + parts[2];
    }
    return parts.join("-");
  };


  CSV2JSON(csv) {
    var array = this.CSVToArray(csv);
    if(array && array.length==1 || array.length==0){
      return({
        'status' :   false,
        'data'   :   'Please upload a valid csv with one yes rotate status user'
      });
    }
    var objArray = [];
    for (var i = 1; i < array.length; i++) {
      objArray[i - 1] = {};
      for (var k = 0; k < array[0].length && k < array[i].length; k++) {

          var key = array[0][k];
          //validate email addresses
          if(k == 6){
            if ( !array[i][7] )
              return({
                  'status' :   false,
                  'data'   :   'Invalid Rotate Status for Server ' + array[i][2] + ' of ' +array[i][5]+ ' user. Valid inputs: yes/no. Line No. ' + i
              });
            if ( array[i][7] && ((array[i][7].trim()).toLowerCase() != 'yes' && (array[i][7].trim()).toLowerCase() != 'no'))
              return({
                  'status' :   false,
                  'data'   :   'Invalid Rotate Status for Server ' + array[i][2] + ' of ' +array[i][5]+ ' user. Valid inputs: yes/no. Line No. ' + i
              });

            if (!array[i][k] && (array[i][7].trim()).toLowerCase() == 'yes'){
              return({
                'status' :   false,
                'data'   :   'Invalid Email Address for Server ' + array[i][2] + ' of ' +array[i][5]+ ' user. Line No. ' + i
              });
            }
            // if (!array[i][k] && (array[i][7].trim()).toLowerCase() == 'no'){
            //   return({
            //     'status' :   false,
            //     'data'   :   'Invalid Email Address for Server ' + array[i][2] + ' of ' +array[i][5]+ ' user. Line No. ' + i
            //   });
            // }

            if (array[i][k]){
              let emailAddresses = (array[i][k].split(',')).filter(n => n);
              if(emailAddresses.length > 0){
                for(let j = 0, len = emailAddresses.length; j < len; j++){
                  if (!this.validateEmailAddress(emailAddresses[j]))
                    return({
                        'status' :   false,
                        'data'   :   'Invalid Email Address for Server ' + array[i][2] + ' of ' +array[i][5]+ ' user. Line No. ' + i
                    });
                }
              }else{
                return({
                  'status' :   false,
                  'data'   :   'Invalid Email Address for Server ' + array[i][2] + ' of ' +array[i][5]+ ' user. Line No. ' + i
                });
              }
            }
          }
          /*
          if(k == 6){
            if (array[i][k].trim() != 'Yes' && array[i][k].trim() != 'No')
                return({
                    'status' :   false,
                    'data'   :   'Invalid Rotate Status for for Server ' + array[i][2] + ' of ' +array[i][4]+ ' user. Valid inputs: Yes/No. Line No. ' + i
                });
          }*/

          objArray[i - 1][key] = array[i][k]
      }
    }
    var json = JSON.stringify(objArray);
    var str = json.replace(/},/g, "},\r\n");
    return({
        'status'    :   true,
        'data'   :   str
    });
  }


  CSVToArray(strData, strDelimiter = null) {
    strDelimiter = (strDelimiter || ",");
    // Create a regular expression to parse the CSV values.
    var objPattern = new RegExp((
        // Delimiters.
    "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
        // Quoted fields.
    "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
        // Standard fields.
    "([^\"\\" + strDelimiter + "\\r\\n]*))"), "gi");
    // Create an array to hold our data. Give the array
    // a default empty first row.
    var arrData = [[]];
    // Create an array to hold our individual pattern
    // matching groups.
    var arrMatches = null;
    // Keep looping over the regular expression matches
    // until we can no longer find a match.
    while (arrMatches = objPattern.exec(strData)) {
        // Get the delimiter that was found.
        var strMatchedDelimiter = arrMatches[1];
        // Check to see if the given delimiter has a length
        // (is not the start of string) and if it matches
        // field delimiter. If id does not, then we know
        // that this delimiter is a row delimiter.
        if (strMatchedDelimiter.length && (strMatchedDelimiter != strDelimiter)) {
            // Since we have reached a new row of data,
            // add an empty row to our data array.
            arrData.push([]);
        }
        // Now that we have our delimiter out of the way,
        // let's check to see which kind of value we
        // captured (quoted or unquoted).
        if (arrMatches[2]) {
            // We found a quoted value. When we capture
            // this value, unescape any double quotes.
            var strMatchedValue = arrMatches[2].replace(
                    new RegExp("\"\"", "g"), "\"");
        } else {
            // We found a non-quoted value.
            var strMatchedValue = arrMatches[3];
        }
        // Now that we have our value string, let's add
        // it to the data array.

        arrData[arrData.length - 1].push(strMatchedValue);
    }
    // Return the parsed data.
    var filtered = arrData.filter(function (el) {
      return el.length == 8 && el[7] != 'No';
    });
    return (filtered);
  }


  currentFormattedDateTime(timeformat:Boolean, formatter:string='-'){
    let dateString = new Date();
    let month = '' + (dateString.getMonth() + 1);
    let day   = '' + dateString.getDate();
    let year  = dateString.getFullYear();
    let hours = dateString.getHours();
    let minutes: number | string = dateString.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes ;
    if(timeformat)
      strTime = strTime + ' ' + ampm;

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    let dateFormatted = (formatter=='-') ? ([year, month, day].join(formatter) + " " + strTime) : ([day, month, year].join(formatter)  + " " + strTime);
    return dateFormatted;
  }

  addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  };

  getUTCTime(time:string) {
    var today = new Date();
    var currentDate = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    var NewDate = currentDate + " "+ time;
    var d = new Date(NewDate);
    var currentDate = d.getFullYear()+'-'+(d.getMonth()+1)+'-'+d.getDate();
    var h = this.addZero(d.getUTCHours());
    var m = this.addZero(d.getUTCMinutes());
    var s = this.addZero(d.getUTCSeconds());
    return h + ":" + m;
  }

  addHourMinuteDate (hour,minute,resetTime=false,roundOff=true) {
    var date = new Date();
    if(resetTime) date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    if(parseInt(hour)!=0) date.setHours( date.getHours() + parseInt(hour) );
    if(parseInt(minute)!=0) date.setMinutes( date.getMinutes() + parseInt(minute)  );
    var hh = this.addZero(date.getHours());
    var mm = this.addZero(date.getMinutes());
    return hh + ':' + ((roundOff)?(Math.ceil(mm/15)*15):mm);
  }

  getUTCDateTime(date:string="",time:string="") {
    var utcDateTIme = (date && time)?new Date(date+' '+time).toISOString():new Date().toISOString()
    let utcDate = utcDateTIme.split('T')[0];
    let utcTime = utcDateTIme.split('T')[1].split(':')[0] + ' ' + utcDateTIme.split('T')[1].split(':')[1];
    return {utcDate : utcDate, utcTime : utcTime}
  }

  convertUTCDateToLocalDate(date) {
    var dateTime = new Date(Date.parse(date));
		return (new Date(dateTime.getTime() - dateTime.getTimezoneOffset()*60*1000));
  }

  formatDateTime(date, formatter:string='-', timeformat:boolean=false){
    let dateString;
    if(date) dateString = new Date(date);
    else  dateString =  new Date();

    let month = '' + (dateString.getMonth() + 1);
    let day   = '' + dateString.getDate();
    let year  = dateString.getFullYear();
    let hours = dateString.getHours();
		let minutes = dateString.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? '0'+hours : hours;
    minutes = minutes < 10 ? '0'+minutes : minutes;
    let strTime = '';
		if(timeformat) strTime = " " + hours + ':' + minutes+ ' ' + ampm ;

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return (formatter=='-')?([year, month, day].join(formatter)+strTime):([day, month, year].join(formatter)+strTime);
  }

   diff_time(expirationDate){
    let currentDate = this.formatDate() + ' ' + Date().slice(16,21);
    let dt1:any = new Date(currentDate);
    let dt2:any = new Date(expirationDate);
    var diffMs = (dt2 - dt1);
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    return {
      days    : diffDays,
      hour    : diffHrs,
      minute  : diffMins
    };
  }

   diff_utc_time(expirationDate){
    let utcDateTime = this.getUTCDateTime()
    let currentDate = utcDateTime.utcDate + " " + (utcDateTime.utcTime.split(" ")[0]) + ":" + (utcDateTime.utcTime.split(" ")[1]);
    let dt1:any = new Date(currentDate);
    let dt2:any = new Date(expirationDate);
    var diffMs = (dt2 - dt1);
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    return {
      days    : diffDays,
      hour    : diffHrs,
      minute  : diffMins
    };
  }

  subtractHourMinuteInDate(dateString='',hour='',minute=''){
		let date = (dateString)?new Date(dateString):(new Date());
		if(hour) date.setHours( date.getHours() - parseInt(hour) );  
		if(minute) date.setHours( date.getMinutes() - parseInt(minute) );
		let year:any = date.getFullYear();
		let month:any = date.getMonth()+1;
		month = (month<10?'0':'')+month;
		let day = (date.getDate()<10?'0':'') + date.getDate();
		hour = (date.getHours()<10?'0':'') + date.getHours();
		minute = (date.getMinutes()<10?'0':'') + date.getMinutes();
		let second = (date.getSeconds()<10?'0':'') + date.getSeconds();

		return year+"-"+month+"-"+day+" "+hour+":"+minute+":"+second;
	}

}
