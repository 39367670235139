import {Component, OnInit, Renderer2} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {HomeService} from './home.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {HttpClient} from '@angular/common/http';
import { environment } from 'enviornments/environment';
import { imagePathHelper } from 'app/helpers/imagePathHelper';
import { Router } from '@angular/router';
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    test: Date = new Date();
    focus;
    focus1;
    dashboardData:any;
    NewsList:any;
    env = environment;
    thumbHelper = imagePathHelper;
    heroSlider: any = [];
    constructor(private fb: FormBuilder,private router: Router, private homeService: HomeService,public http:HttpClient) { }

    

    ngOnInit() {
        this.getDashboard();
        this.getNewsList();
        this.sliders();
    }

    getDashboard() {
        this.homeService.list().subscribe(res => {
            if (res.success) this.dashboardData = res.data;
        },error => {
            this.dashboardData = [];
        });
    }

    getNewsList() {
        this.homeService.Newslist().subscribe(res => {
            if (res.success) {
                this.NewsList = res.data;
            }
        },error => {
            this.NewsList = [];
        });
        
    }

    NewsDetails(id){
        this.router.navigateByUrl(`news-detail/${id}`);
    }

    blogDetails(id){
        this.router.navigateByUrl(`travelogues-detail/${id}`);
    }


    sliders() {
        this.homeService.sliders().subscribe( res => {
            if (res.success) {
                this.heroSlider = res.data.filter( el => el.page_level === 0 )
            }
        })
    }
}
