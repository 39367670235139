import { Injectable } from '@angular/core';
import { BaseService } from '../../_services/base.service';

@Injectable()
export class CommunityService {

    constructor(private baseService: BaseService) { }

    getFollowersList() {
        return this.baseService.getRequest(`user/follow/users`).toPromise();
    }

    requestFollow(post) {
        return this.baseService.post(`user/follow/send`, post);
    }
    profile(){
        return this.baseService.get(`user/profile`);
    }

    requestUnFollow(post) {
        return this.baseService.post(`user/follow/action`, post);
    }

    allGroupList(){
        return this.baseService.get(`group`).toPromise(); 
    }
}
