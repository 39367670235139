import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from "enviornments/environment";
import { CookiesService } from '../shared/services/cookies.services';
import { EncryptionService } from '../shared/services/encryption.services';
import * as CryptoJS from 'crypto-js';
import { Globals } from '../globals';
import Swal from "sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // Variables
  authUrl = 'http://localhost:8000/oauth/token';
  apiUrl = 'http://localhost:8000/api';
  options: any;
  /**
   * Constructor
   * @param http The http client object
   * @param cookieService
   * @param encryptionServices
   * @param globals
   */
  constructor(
      private http: HttpClient, private cookieService: CookiesService,private encryptionServices:EncryptionService,private globals: Globals
  ) {
    this.options = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
    };
  }
  /**
   * Get an access token
   * @param e The email address
   * @param p The password string
   */
  login(e: string, p: string) {
    return this.http.post(this.authUrl, {
      client_id: '6',
      client_secret: 'WAaE1u5KdAhf7yBUzMb3JP8fNuoPNMw3WSR16GVT',
      grant_type: 'password',
      password: p,
      scope: '*',
      username: e
    }, this.options);
  }

  doLogin(e: string, p: string) {
    return this.http.post(environment.url + 'auth/login', {
      email: e,
      password: p
    }, this.options);
  }
  
  /**
   * Revoke the authenticated user token
   */
  logout() {
    this.options.headers.Authorization = this.encryptionServices.decrypt(this.cookieService.get(this.globals.tw_cookie)).toString(CryptoJS.enc.Utf8);
    return this.http.get(this.apiUrl + '/token/revoke', this.options);
  }

  public getAppToken():string{
    if(this.cookieService.get(this.globals.tw_cookie)){
      return this.encryptionServices.decrypt(this.cookieService.get(this.globals.tw_cookie)).toString(CryptoJS.enc.Utf8);
    }
  }

  public getLoggedInUser(){
    if(this.cookieService.get(this.globals.tw_user_cookie)){
      return JSON.parse(this.encryptionServices.decrypt(this.cookieService.get(this.globals.tw_user_cookie)).toString(CryptoJS.enc.Utf8));
    }
  }

  activeSessions(timeout,errorMsg) {

    setTimeout(()=>{

      if(errorMsg){
        Swal.fire('Error', errorMsg, 'error');
      }

      (this.cookieService.check(this.globals.tw_cookie)) ? this.cookieService.delete(this.globals.tw_cookie, this.globals.hostname): '';
      (this.cookieService.check(this.globals.tw_cookie)) ? this.logout() : '';
      (this.cookieService.check(this.globals.tw_welcome_cookie)) ? this.cookieService.delete(this.globals.tw_welcome_cookie, this.globals.hostname): '';
      (this.cookieService.check(this.globals.tw_user_cookie)) ? this.cookieService.delete(this.globals.tw_user_cookie, this.globals.hostname): '';
      (this.cookieService.check(this.globals.tw_sso_cookie)) ? this.cookieService.delete(this.globals.tw_sso_cookie, this.globals.ssohostname): '';
      (this.cookieService.check(this.globals.tw_spa_cookie)) ? this.cookieService.delete(this.globals.tw_spa_cookie, this.globals.hostname): '';
      localStorage.removeItem('currentUserID');
      localStorage.setItem("refresh-mode", "true");
      window.location.href = "/";

    },timeout);

  }
}
