import {Component, OnInit} from '@angular/core';
import {UserProfileService} from './../user_profile.service';
import {Router, ActivatedRoute} from '@angular/router';
import { generalHelper } from 'app/helpers/generalHelper'; 
import {ConfirmationService} from 'primeng/api';
import {CommunityService} from '../../../website/community/community.service';
import { ProfileService } from '../../../pages/profile/profile.service';
import Swal from "sweetalert2";
import {environment} from "enviornments/environment"
import { imagePathHelper } from "../../../helpers/imagePathHelper";
import {HttpClient, HttpEventType} from '@angular/common/http';
import { AuthService } from 'app/services/auth.service';
@Component({
    selector: 'app-user-common-profile',
    templateUrl: './common-profile.component.html',
    styleUrls: ['./common-profile.component.scss']
})
export class UserCommonProfileComponent implements OnInit {
    env=environment;
    loading: boolean;
    errors: boolean;
    detail = false;
    readmorebtn = true;
    timeline:any;
    generalHelper = generalHelper;
    user:any={};
    token: any;
    CoverimagePath:any;
    ProfileimagePath:any;
    imagePath = imagePathHelper;
    Currentuser:any
    UserId:any;
    HeaderImage:any
    comment: string = '';
    uploadStatus: number;
    postsList: any = [];
    attachment: File = null;
    ComunityUser:any;
    constructor(private UserProfileService: UserProfileService,private route: ActivatedRoute,private profileService: ProfileService,private CommunityService: CommunityService,private confirmationService: ConfirmationService,private router: Router , private authService: AuthService) { }

    ngOnInit() {
        this.token = this.route.snapshot.params.username;
        this.getUserProfile();
        this.getCOmmunityUserList()
    }

    getCOmmunityUserList() {
        this.CommunityService.getFollowersList().then((res) => {
            if (res.success) {
                this.ComunityUser = res.data.filter(el=> el.username ==  this.token);
                console.log('con',this.ComunityUser)
            }
        }).catch((error) => {});
    }


    switchToUserHistory(username){
        this.router.navigateByUrl(`Users/TravelHistory/${username}`);
    }
    getUserCurrentProfile() {
        this.profileService.profile().subscribe(res => {
            this.Currentuser = res.id;
        },error => {});
    }

    switchToUserRoutes(username){
        this.router.navigateByUrl(`Users/Routes/${username}`);
    }

    // switchToUserFollowers(username){
    //     this.router.navigateByUrl(`userprofile/${username}`);
    // }

    getUserProfile(){
        this.UserProfileService.getUserProfile(this.token).subscribe(res => {
            this.user = res.data;
            this.UserId=this.user.id;
            this.HeaderImage=this.user.header_image;
            this.CoverimagePath=environment.imageURL+'images/'+this.UserId+'/cover/'+this.HeaderImage;
            this.ProfileimagePath=environment.imageURL+'images/'+this.UserId+'/profile/'+this.user.profile_picture;
            this.postsList=this.user.posts;
        },error => {});
    }

    async requestFollow(ID) {
        const params = {
            'to_user': ID,
            'from_user':this.Currentuser
        }
        Swal.fire({
            icon: 'info',
            title: 'Send Request',
            showCancelButton: true,
            html: '`Do you want to send your request ?',
            confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                this.CommunityService.requestFollow(params).subscribe((res: any) => {
                    this.getUserProfile();
                    if(res.success){
                        this.getUserProfile();
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: res.message
                        });
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: res.message
                        });
                    }
                }, (err: any) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: err.error
                        
                    });
                });
            }
    });

    } 

    async requestUnFollow(requestId,action) {
        
        const params = {
            requestId: requestId,
            action : action
        }
        Swal.fire({
            icon: 'info',
            title: 'Action Request',
            showCancelButton: true,
            html: 'Do you want to take this action ?',
            confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                this.CommunityService.requestUnFollow(params).subscribe((res: any) => {
                    this.getUserProfile();
                    if(res.success){
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: res.message
                        });
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: res.message
                            
                        });
                    }
                }, (err: any) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: err.message
                        
                    });
                });
            }
    });

    }

    getConfirmation(header: string, message: string) {
        return new Promise((resolve, reject) => {
            this.confirmationService.confirm({
                message: message,
                header: header,
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    resolve(true);
                },
                reject: () => {
                    resolve(false);
                }
            });
        });
    }


}
