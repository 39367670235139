import { Component, OnInit, ElementRef } from '@angular/core';
import {ActivatedRoute,	Router} from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Globals } from 'app/globals';
import { CookieService } from 'ngx-cookie-service';
@Component({
    selector: 'app-navnavbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    private toggleButton: any;
    private sidebarVisible: boolean;
    welcomenotify:boolean = false;
    // tslint:disable-next-line:triple-equals
    AdvancedPatchPageSelected: boolean = ((this.router.url.split('/'))[2] == 'signup') ? false : true;
    constructor(public location: Location, private router: Router, private element: ElementRef,private cookieService: CookieService,private globals: Globals) {
        this.sidebarVisible = false;
    }

    ngOnInit() {
        this.handleWelcomeNotify();
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        // tslint:disable-next-line:no-unused-expression
        this.AdvancedPatchPageSelected;
    }
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];

        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };
    isHome() {
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }
        if( titlee === '/home' ) {
            return true;
        }
        else {
            return false;
        }
    }
    isDocumentation() {
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }
        if( titlee === '/documentation' ) {
            return true;
        }
        else {
            return false;
        }
    }

    loginPage() {
        this.router.navigate(['login'])
    }

    handleWelcomeNotify(){

        if(this.cookieService.get(this.globals.tw_welcome_cookie)){
          this.welcomenotify  = true;
          setInterval(() => {        
            this.cookieService.delete(this.globals.tw_welcome_cookie, '/', this.globals.hostname);
            this.welcomenotify  = false;
          }, 6000);
        }
        
      }
}
