import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { WebsiteSidebarService } from './websitesidebar.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ConfirmationService} from 'primeng/api';
import { HomeService } from 'app/website/home/home.service';
import {MessageService} from 'primeng/api';
import { AuthService } from 'app/services/auth.service';
import { Location } from '@angular/common';

@Component({
    selector: 'app-websitesidebar',
    templateUrl: './websitesidebar.component.html',
    styleUrls: ['./websitesidebar.component.scss']
})

export class WebsiteSidebarComponent implements OnInit {
    @ViewChild('myInput')
    myInputVariable: ElementRef;
    thoughtmodal=false;
    user: any = {};
    ThoughtDayForm: FormGroup;
    dashboardData:any;
    loginUser=false;
    NewsList:any;
    constructor( private homeService: HomeService,  private authService: AuthService, private router: Router,private fb: FormBuilder, private messageService: MessageService, private confirmationService: ConfirmationService,private location: Location) { }

    ngOnInit() {
        this.getDashboard();
        this.getNewsList();
        if(this.authService.getLoggedInUser()){
            this.loginUser=true;
        }
    }

    getDashboard() {
        this.homeService.list().subscribe(res => {
            if (res.success) this.dashboardData = res.data;
        },error => {
            this.dashboardData = [];
        });
    }

    getNewsList() {
        this.homeService.Newslist().subscribe(res => {
            if (res.success) {
                this.NewsList = res.data;
            }
        },error => {
            this.NewsList = [];
        });
        
    }

    NewsDetails(id){
        this.router.navigateByUrl(`news-detail/${id}`);
    } 

    GoTravelogues(){
        this.router.navigate(['travelogues'])
    }
    GoCommunity(){
        this.router.navigate(['community'])
    }
    GoNewsHome(){
        this.router.navigate(['all-news'])
    }
    GoForum(){
        this.router.navigate(['forum'])
    }
    GoTravBazaar(){
        this.router.navigate(['TravBazaar'])
    }

    GoWebsite(){
        this.router.navigate(['./'])
    }

    

   

}
