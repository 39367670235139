import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import {MessageService} from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import Swal from "sweetalert2";
import { AuthService } from 'app/services/auth.service';
import {UserProfileService} from './../../user_profile.service';
import { environment } from 'enviornments/environment';
@Component({
    // tslint:disable-next-line:component-selector
    selector: 'app-OtherUserblog_listing',
    templateUrl: './blog_listing.component.html',
    styleUrls: ['./blog_listing.component.scss']
})

export class OtherUserBlogListingComponent implements OnInit {
    env=environment;
    user : any;
    loading: boolean;
    errors: boolean;
    options: any;
    description:any;
    overlays: any[];
    display: boolean = false;
    token: any;
    blogs: any = [];
    contentLoader: boolean = false;
    constructor( private UserProfileService: UserProfileService,private router: Router,private route: ActivatedRoute,private authService: AuthService, private confirmationService: ConfirmationService, private messageService: MessageService) { }

    ngOnInit() {
        this.token = this.route.snapshot.params.username;
        this.getBlogs();
    }

    blogDetails(id){
        this.router.navigateByUrl(`solo/blog/detail/${id}`);
    }


    getBlogs() {
        this.UserProfileService.getUserProfile(this.token).subscribe(res => {
        if (res.success) {
            this.blogs = res.data.blogs;
        }
        },error => {
            this.blogs = [];
        });
        
    }

    

    switchToUserProfile(username){
        this.router.navigateByUrl(`userprofile/${username}`);
    }
}
