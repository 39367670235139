import { Injectable } from '@angular/core';
import { BaseService } from '../../../_services/base.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class GroupFollowersService {
    public followers : any  = [] ;
    public followedUser : any  = [] ;
    constructor(private baseService: BaseService) { }
    getFollowersPendingList() {
        return this.baseService.getRequest(`group/follow/pending-requests`).toPromise();
    }

    getFollowersList() {
        return this.baseService.getRequest(`group/follow/list`).toPromise();
    }

    requestFollow(post) {
        return this.baseService.post(`group/follow/action`, post);
    }

    getUserFollowersList() {
        return this.baseService.getRequest(`group/follow/users`).toPromise();
    }


}
