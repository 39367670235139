import { Component, OnInit } from '@angular/core';
import { SoloRoutesTravelHistoryService } from '../solo_routes_travelhistory.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {Router, ActivatedRoute} from '@angular/router';
import Swal from "sweetalert2";
@Component({
    selector: 'app-editsoloroutes',
    templateUrl: './edit.component.html',
    styleUrls: ['./edit.component.scss']
})

export class EditSoloRouteComponent implements OnInit {
    user: any = {};
    loading: boolean;
    errors: boolean;
    AddRouteForm: FormGroup;
    options: any;
    soloRoutes: any = [];
    overlays: any[];
    display = false;
    token: any;
    // tslint:disable-next-line:no-shadowed-variable
    constructor(private SoloRoutesTravelHistoryService: SoloRoutesTravelHistoryService, private confirmationService: ConfirmationService, private messageService: MessageService, private fb: FormBuilder, private router: Router, private route: ActivatedRoute) { }

    ngOnInit() {
        this.token = this.route.snapshot.params.id;
        this.AddRouteForm = this.fb.group({
            date_time: ['', [Validators.required]],
            country: ['', [Validators.required]],
            city: ['', [Validators.required]],
            state: ['', [Validators.required]],
            start_latLng: ['', [Validators.required]],
            end_latLng: ['', [Validators.required]],
            total_km: ['', [Validators.required]],
            description: ['', [Validators.required]],
            privacy_status: ['', [Validators.required]],
        });
        this.options = {
            center: {lat: 36.890257, lng: 30.707417},
            zoom: 12

        };
        this.getRoutesPatch();
    }

    showDialog() {
        this.display = true;
    }
    ShowRoutesList() {
        this.router.navigate(['solo/routes'])
    }

    get trip_name() {
        return this.AddRouteForm.controls.trip_name
    }
    get date() {
        return this.AddRouteForm.controls.date
    }
    get country() {
        return this.AddRouteForm.controls.country
    }
    get city() {
        return this.AddRouteForm.controls.city
    }
    get state() {
        return this.AddRouteForm.controls.state
    }
    get start_latLng() {
        return this.AddRouteForm.controls.start_latLng
    }
    get end_latLng() {
        return this.AddRouteForm.controls.end_latLng
    }
    get total_km() {
        return this.AddRouteForm.controls.total_km
    }
    get description() {
        return this.AddRouteForm.controls.description
    }
    get privacy_status() {
        return this.AddRouteForm.controls.privacy_status
    }

    getRoutesPatch() {
               const ID  = this.token;

        this.SoloRoutesTravelHistoryService.getSoloRoutesEdit(ID).then((res) => {
                this.soloRoutes = res.data;
                if (res) {
                    this.AddRouteForm.patchValue({
                        date_time: this.soloRoutes.date_time,
                        country: this.soloRoutes.country,
                        state: this.soloRoutes.state,
                        city: this.soloRoutes.city,
                        start_latLng: this.soloRoutes.start_latLng,
                        end_latLng: this.soloRoutes.end_latLng,
                        total_km: this.soloRoutes.total_km,
                        description: this.soloRoutes.description,
                        privacy_status: this.soloRoutes.privacy_status,
                    });
                }
            });

    }
    async addRoute(formData) {
        const params = {
            'id': this.token,
            // 'date_time': formData.date_time,
            'date_time': '2021-09-26 00:00',
            'country': formData.country,
            'state': formData.state,
            'city': formData.city,
            'start_latLng': formData.start_latLng,
            'end_latLng': formData.end_latLng,
            'total_km': formData.total_km,
            'description': formData.description,
            'privacy_status': formData.privacy_status
        }

        Swal.fire({
            icon: 'info',
            title: 'Add Route',
            showCancelButton: true,
            html: 'Do you want to add this route ?',
            confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                this.SoloRoutesTravelHistoryService.addRoute(params)
                .subscribe((res: any) => {
                    this.router.navigate(['/solo/routes']);
                    this.messageService.add({severity: 'success', summary: 'Success', detail: res.message ? res.message : ""});
                }, (err: any) => {
                    this.errors = true;
                    this.messageService.add({severity: 'error', summary: 'Error', detail: err.error ? err.error.error : ""})
                });
            }
    });

    }

    getConfirmation(header: string, message: string) {
        return new Promise((resolve, reject) => {
            this.confirmationService.confirm({
                message: message,
                header: header,
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    resolve(true);
                },
                reject: () => {
                    resolve(false);
                }
            });
        });
    }
}
