import { Injectable } from '@angular/core';
import { BaseService } from '../../_services/base.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FollowersService {
    public followers : any  = [] ;
    public followedUser : any  = [] ;
    constructor(private baseService: BaseService) { }
    getFollowersPendingList() {
        return this.baseService.getRequest(`user/follow/pending-requests`).toPromise();
    }

    getFollowersList() {
        return this.baseService.getRequest(`user/follow/list`).toPromise();
    }

    requestFollow(post) {
        return this.baseService.post(`user/follow/action`, post);
    }

    getUserFollowersList() {
        return this.baseService.getRequest(`user/follow/users`).toPromise();
    }

    // setFollowers(data){
    //     this.followers = data;
    // }
    
    // setFollowedUser(data){
    //     this.followedUser = data;
    //     console.log('jiknjhn',data);
    // }

    // getFollowers(){
    //     return this.followers;
    // }

    // getFollowedUser(){
    //     return this.followedUser;
    // }

}
