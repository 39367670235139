import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { SidebarService } from './sidebar.service';
import { FollowersService } from '../followers/followers.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ConfirmationService} from 'primeng/api';
import { ProfileService } from '../profile/profile.service';
import {MessageService} from 'primeng/api';
import { Location } from '@angular/common';
import { imagePathHelper } from "../../helpers/imagePathHelper";
import Swal from "sweetalert2";
import { AuthService } from 'app/services/auth.service';
import { Globals } from 'app/globals';
import { CookiesService } from 'app/shared/services/cookies.services';
import { EncryptionService } from 'app/shared/services/encryption.services';
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
    @ViewChild('myInput')
    myInputVariable: ElementRef;
    thoughtmodal=false;
    user: any;
    Allfollowers:any=[];
    FollowedUser:any=[];
    imagePath = imagePathHelper;
    ThoughtDayForm: FormGroup;
    editProfileUrl:boolean = (((this.router.url.split("/"))[2] == 'profile'));
    constructor(private cookieService: CookiesService, private encryptionServices: EncryptionService, private globals: Globals,private followersService: FollowersService,private SidebarService: SidebarService,private profileService: ProfileService, private router: Router,private fb: FormBuilder, private messageService: MessageService, private confirmationService: ConfirmationService,private location: Location,public authService: AuthService) { }

    ngOnInit() {
        this.user = this.authService.getLoggedInUser();
        this.ThoughtDayForm = this.fb.group({
            thought: ['', [Validators.required]],
        });
    }

    get thought() {
        return this.ThoughtDayForm.controls.thought
    }
    // getFollowersList() {
    //     // console.log('jdvjdvjdbvjdbgh',this.followersService.getFollowers());
    //     // this.Allfollowers=this.followersService.getFollowers();
    //     // this.FollowedUser=this.followersService.getFollowedUser();
    //     this.followersService.getFollowersList().then((res) => {
    //         if (res.success) {
    //             this.Allfollowers = res.data.followingMe;
    //             this.FollowedUser = res.data.followedByMe
    //         }
    //     }).catch((error) => {this.Allfollowers=[];});
    // }
    async updateThought(formData) {
        const params = {
            'thought': formData.thought,
        }
        Swal.fire({
            icon: 'warning',
            title: 'Thought of the day',
            showCancelButton: true,
            html: 'Do you want to update/change your thought ?',
            confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                this.SidebarService.updateThoughtDay(params).subscribe((res: any) => {
                    
                    if(res.success){
                        this.getUserProfile();
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: res.message
                        });
                        this.thoughtmodal=false;
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: res.message
                        });
                        this.thoughtmodal=false;
                    }
                }, (err: any) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: err.error
                        
                    });
                    this.thoughtmodal=false;
                });
            }
    });

    }

    getUserProfile() {
       // this.FollowedUser=[];
        this.profileService.profile().subscribe(res => {
            //this.user = res;  
            this.cookieService.set(this.globals.tw_user_cookie, this.encryptionServices.encrypt(JSON.stringify(res)));
            this.user = this.authService.getLoggedInUser();       
        },error => {});
    }
      

    GoTravelHistory(){
        this.router.navigate(['solo/travel-history'])
    }
    GoAddBlog(){
        this.router.navigate(['solo/blogs'])
    }
    GoReferral(){
        this.router.navigate(['solo/referral'])
    }
    GoAddBrand(){
        this.router.navigate(['solo/list_brand'])
    }

    GoFollowers(){
        this.router.navigate(['solo/followers'])
    }

    GoRoutes(){
        this.router.navigate(['solo/routes'])
    }
    GoInbox(){
        this.router.navigate(['solo/messages/send'])
    }

    GoSoloSettings(){
        this.router.navigate(['solo/profile/settings'])
    }
    
    GoNews(){
        this.router.navigate(['solo/news/list'])
    }

    thoughtModal(){
        this.thoughtmodal=true;
        this.ThoughtDayForm.controls['thought'].setValue(this.user.thought);
    }


    goBack() {
        this.location.back();
    }

      switchToUserProfile(username){
        this.router.navigateByUrl(`userprofile/${username}`);
    }
}
