import { Component, OnInit } from '@angular/core';
import { AddBrandService } from './add_brand.service';
import { generalHelper } from '../../../helpers/generalHelper';
import Swal from "sweetalert2";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {ActivatedRoute,Router} from '@angular/router';

@Component({
    selector: 'app-addbrand',
    templateUrl: './add_brand.component.html',
    styleUrls: ['./add_brand.component.scss']
})

export class AddBrandComponent implements OnInit {
    generalHelper = generalHelper;
    AddBrandForm: FormGroup;
    user: any = {};
    loading: boolean;
    errors: boolean;
    addBrand:boolean;
    endoresd:any;
    Brand:any;
    BrandId: any = [];
    BrandName: any = [];
    filteredBrandId:any = [];
    filteredBrandName:any = [];
    FilterBrandUniqueId:any;
    editEndoresd:any;
    SelectedBrandName:any;
    SelectedBrandId:any;
    contentLoader:boolean;
    editbrandId:any;
    editEndoresedBrand:boolean=false;
    constructor(private BrandService: AddBrandService,private route: ActivatedRoute, private fb: FormBuilder,private confirmationService: ConfirmationService, private messageService: MessageService, private router: Router) { }

    ngOnInit() {
        this.getBrand();
        this.getEndoresd();
        this.AddBrandForm = this.fb.group({
            brand_id: [''],
            brand_name: ['', [Validators.required]],
            status: ['', [Validators.required]],
            show_on_profile: ['', [Validators.required]],
            expiration_date: ['', [Validators.required]],
        });
        this.route.snapshot.params.id ? this.editBrandPatch(this.route.snapshot.params.id) : '';
        this.route.snapshot.params.id ? (this.editEndoresedBrand = true) :  (this.editEndoresedBrand = false);
    }

    get brand_id() {
        return this.AddBrandForm.controls.brand_id
    }

    get brand_name() {
        return this.AddBrandForm.controls.brand_name
    }

    get status() {
        return this.AddBrandForm.controls.status
    }

    get show_on_profile() {
        return this.AddBrandForm.controls.show_on_profile
    }
    get expiration_date() {
        return this.AddBrandForm.controls.expiration_date
    }

    getBrand() {
        this.BrandService.getBrand().toPromise().then(res => {
            this.filteredBrandName = [];
            this.Brand = res;
            for (let BName of this.Brand) {
                this.filteredBrandName.push({ label: BName.name, value: BName.name });
                // this.FilterBrandUniqueId=BName.unique_id;
              }
        }).catch(e => {});
    }

    searchBrandId(event){
        this.filteredBrandId = [];
        const filteredBrandId = this.Brand.filter(el => { return el.unique_id.includes(event.query) });
        for (let BId of filteredBrandId) {
            this.filteredBrandId.push({ label: BId.unique_id, value: BId.unique_id });
          }
    }
    
    getEndoresd() {
        this.BrandService.getEndoresd().toPromise().then(res => {
            this.endoresd = res;
        }).catch(e => {});
    }

    BrandNameEvent(event){
        const selectedBrandArray=this.Brand.filter(el => el.name== event.value.value);
        this.SelectedBrandId = selectedBrandArray[0].unique_id;
        this.FilterBrandUniqueId=this.SelectedBrandId;
        this.AddBrandForm.controls['brand_id'].setValue(this.SelectedBrandId);
    }

    
    addBrandModal() {
        this.addBrand = true;
        this.SelectedBrandName=[];
    }


    async AddBrand(formData) {
        const params = {
            'id':'',
            'brand_id': this.FilterBrandUniqueId,
            'status': formData.status,
            'show_on_profile': formData.show_on_profile,
            'brand_name': formData.brand_name.label,
            'expiration_date': formData.expiration_date.toLocaleDateString(),
        }

        Swal.fire({
            icon: 'info',
            title: 'Add Brand',
            showCancelButton: true,
            html: 'Do you want to add this brand ?',
            confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                this.BrandService.addBrands(params)
                .subscribe((res: any) => {
                    //this.AddBrandForm.reset();
                    if(res.success){
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: res.message
                        });
                        this.getBrand();
                        this.AddBrandForm.reset();
                        this.addBrand = false;
                        this.router.navigate(['solo/list_brand']);
                    }else{
                        this.errors = true;
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: res.message
                        }) ;
                    }
                    
                }, (err: any) => {
                    this.errors = true;
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: err
                    });
                    this.getBrand();
                    this.getEndoresd();
                    this.AddBrandForm.reset();
                        this.addBrand = false;
                });
            }
    });

    }
    
    async editBrand(formData) {
        console.log(formData);
        const params = {
            //'uuid':this.route.snapshot.params.id,
            'id': this.route.snapshot.params.id,
            'brand_id': formData.brand_id,
            'status': formData.status,
            'show_on_profile': formData.show_on_profile,
            'brand_name': formData.brand_name.label,
            'expiration_date': formData.expiration_date.toLocaleDateString(),
        }

        Swal.fire({
            icon: 'warning',
            title: 'Edit Brand',
            showCancelButton: true,
            html: 'Do you want to edit this brand ?',
            confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                this.BrandService.addBrands(params)
                .subscribe((res: any) => {
                    //this.AddBrandForm.reset();
                    if(res.success){
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Brand Updated successfully'
                        })
                        this.getBrand();
                        this.AddBrandForm.reset();
                        this.addBrand = false;
                        this.router.navigate(['solo/list_brand']);
                    }else{
                        this.errors = true;
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: res.message
                        }) ;
                        this.AddBrandForm.reset();
                        this.addBrand = false;
                    }
                    
                }, (err: any) => {
                    this.errors = true;
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: err
                    });
                    this.AddBrandForm.reset();
                        this.addBrand = false;
                });
            }
    });

    }

    editBrandPatch(id) {
        this.contentLoader=true;
        this.BrandService.detailsBrand(id).toPromise().then(res => {
            if (res.success) {
                this.AddBrandForm.patchValue({
                    brand_id: res.data.brand_id,
                    status: res.data.status,
                    show_on_profile: res.data.show_on_profile,
                   // brand_name: res.data.brand_name,
                    expiration_date: new Date(res.data.expiration_date),
                });
                this.editbrandId=res.data.id;
                this.SelectedBrandName= {label: res.data.brand_name, value: res.data.brand_name };
                console.log('555555555',this.SelectedBrandName);
        }}).catch((back) => {
            this.contentLoader=false;
        });
        
    }
}
