import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class Globals {

  constructor(private router:Router) {}

  getDomainName(domain){
    var splitURL = domain.split(".");
    return splitURL[1]+'.'+splitURL[2];
  }
  
  /**
   * Default cookies for application
   */
  tw_cookie: string = '_Secure-TWID';
  tw_user_cookie: string = '_Secure-TWUser';
  tw_welcome_cookie: string = '_Welcome-TW';
  tw_spa_cookie: string = '_login-SPATW';
  tw_sso_cookie: string = '_Secure-SSOTW';

  hostname:string = window.location.hostname;
  ssohostname:string = this.getDomainName(window.location.hostname);
  cookieSecured:boolean = (window.location.protocol == "http:") ? false : true;
  cookieExpiration:number = 1; //1 day
  //cookieExpiration:number = 0.000277778; //24 Seconds

  sessionValidateTime:number = 15000; //30 seconds

  //gmailClientId:string = "141735486242-3oeagk7j44k5v2hednn23mkp556dn4ea.apps.googleusercontent.com";

}