import { Injectable } from '@angular/core';
import { BaseService } from '../../_services/base.service';

@Injectable()
export class TraveloguesService {

    constructor(private baseService: BaseService) { }

    profile(){
        return this.baseService.get(`user/profile`);
    }

    addUpdate(params) {
        return this.baseService.postFile(`user/blogs/addUpdate`, params);
    }

    list() {
        return this.baseService.getRequest(`blogs`);
    }

    details(id) {
        return this.baseService.getRequest(`blogs/`+id);
    }

    delete(id) {
        return this.baseService.delete(`user/blogs/delete/`+id);
    }

    comment(params) {
        return this.baseService.post(`user/blogs/comment`,params);
    }

    like(params) {
        return this.baseService.post(`user/blogs/like`,params);
    }

    getBusinessType() {
        return this.baseService.getRequest(`categories/7`).toPromise();
    }

}
