import { Injectable } from '@angular/core';
import { BaseService } from '../../../_services/base.service';

@Injectable()
export class GroupMembersService {

    constructor(private baseService: BaseService) { }

    
    }


