import {Component, OnInit, Renderer2} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {ForumService} from './forum.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {HttpClient,HttpEventType} from '@angular/common/http';
import { generalHelper } from "../../helpers/generalHelper";
import { imagePathHelper } from "../../helpers/imagePathHelper";
import { AuthService } from 'app/services/auth.service';
import {ActivatedRoute, Router} from "@angular/router";
import { environment } from 'enviornments/environment';
@Component({
    selector: 'app-websiteforum',
    templateUrl: './forum.component.html',
    styleUrls: ['./forum.component.scss']
})
export class ForumComponent implements OnInit {
    env=environment;
    user :any;
    generalHelper = generalHelper;
    manageFormDisplay:boolean=false;
    PostForm:FormGroup;
    selectedListing:any='AllListing';
    allforumList:any=[];
    myforumList:any=[];
    imagePath = imagePathHelper.profileImage;
    contentLoader:boolean=false;
    commetIndex:number;
    commetReplyIndex:number;
    comment2: string = '';
    MYcommetIndex:number;
    comment: string = '';
    attachment: File = null;
    uploadStatus: number;
    MYcommetReplyIndex: number;
    constructor(private fb: FormBuilder, private router: Router, private forumService: ForumService,public http:HttpClient,public authService: AuthService) { }

    

    ngOnInit() {
        this.user = this.authService.getLoggedInUser();
        this.manageQuestionForm();
        this.getMYForum();
        this.getAllForum();
    }

    manageQuestionForm(){
        this.PostForm = this.fb.group({
            id: [''],
            question: ['', [Validators.required]],
            taging: ['', [Validators.required]],
            description: ['', [Validators.required]],
        });
    }

    get id() {
        return this.PostForm.controls.id
    }

    get question() {
        return this.PostForm.controls.question
    }
    get taging() {
        return this.PostForm.controls.taging
    }
    get description() {
        return this.PostForm.controls.description
    }


    getMYForum() {
        this.forumService.getMyForumList().toPromise().then(res => {
            if(res){
                this.myforumList = res;
                console.log('rsss',this.myforumList);
            }else{
                this.myforumList = [];
            }
            
        }).catch(e => {});
    }

    getAllForum() {
        this.forumService.getAllForumList().toPromise().then(res => {
            this.allforumList = res;
        }).catch(e => {});
    }

    GoToLoginPage(){
        this.router.navigateByUrl(`login`);
    }

    manageQuestion(formData){
        this.manageFormDisplay = false;
        const params = {
            'id': formData.id,
            'title': formData.question,
            'tags': (formData.taging).toString(),
            'description': formData.description,
        }

        Swal.fire({
            icon: 'info',
            title: 'Post Forum',
            showCancelButton: true,
            html: 'Do you want to post this forum ?',
            confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
        }).then((result) => {            
            if (result.isConfirmed) {
                this.forumService.addPost(params)
                .subscribe((res: any) => {
                    //this.AddBrandForm.reset();
                    if(res.success){
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: res.message
                        });
                        this.getMYForum();     
                        this.getAllForum();     
                        this.PostForm.reset();
                        this.manageFormDisplay = false;
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: res.message
                        }) ;
                        this.manageFormDisplay = true;
                    }
                    
                }, (err: any) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: err
                    });
                    this.getMYForum();
                    this.getAllForum();
                    this.PostForm.reset();
                    this.manageFormDisplay = false;
                });
            }
    });
    }

    getForumList(val){
        this.selectedListing=val;
    }

    postQuestions(){
        this.manageFormDisplay=true;
    }

    editforum(uuid) {
        this.contentLoader=true;
        this.manageFormDisplay=true;
        this.forumService.detailsPatch(uuid).toPromise().then(res => {
            if (res.success) {
                this.PostForm.patchValue({
                    id : res.id,
                    question : res.data.title,
                    description : res.data.description,
                    taging : (res.data.tags).split(','),
                });
                this.contentLoader=false;
            }
        }).catch((back) => {
            this.contentLoader=false;
        });
    }

    deleteforum(uuid) {
        this.contentLoader=true;
        Swal.fire({
            icon: 'info',
            title: 'Post Forum',
            showCancelButton: true,
            html: 'Do you want to post this forum ?',
            confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
        }).then((result) => {            
            if (result.isConfirmed) {
                this.forumService.delete(uuid).subscribe(res => {
            
                    if (res.success){
                        this.contentLoader=false;
                        this.getMYForum();
                        this.getAllForum();
                        Swal.fire('Delete Forum', res.message, 'success'); 
                    }else {Swal.fire('Delete Forum',res.message, 'error');this.contentLoader=false;}
                },error => {
                    Swal.fire('Delete Forum',error.message, 'error');
                    this.contentLoader=false;
                });
            }else{
                this.contentLoader=false;
            }
        })
                
    }

    commentShow(number){
        this.commetIndex=number;
    }
    
    replyShow(number){
        this.commetReplyIndex=number;
    }

    postComment(postIdx, commentId = null){
        let post = this.allforumList[postIdx];
        const formData = new FormData();
        formData.append('id',(!commentId) ? '' : commentId);
        formData.append('forum_id',post.uuid);
        formData.append('comment',this.comment);
        formData.append('attachment',this.attachment);
        this.forumService.doComment(formData).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress){
                this.uploadStatus = Math.round(event.loaded / event.total * 100)
            } else if (event.type === HttpEventType.Response){
                this.comment = '';
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: event.body.message
                });
                this.allforumList=[];
                this.myforumList=[];
                this.getAllForum();
                this.getMYForum();
            }
        }, error => {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message
            })
        });
    }

    MYcommentShow(number){
        this.MYcommetIndex=number;
        console.log('inx',number)
    }

    MYreplyShow(number){
        this.MYcommetReplyIndex=number;
    }

    MYpostComment(postIdx, commentId = null){
        let post = this.allforumList[postIdx];
        const formData = new FormData();
        formData.append('id',(!commentId) ? '' : commentId);
        formData.append('forum_id',post.uuid);
        formData.append('comment',this.comment);
        formData.append('attachment',this.attachment);
        this.forumService.MYdoComment(formData).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress){
                this.uploadStatus = Math.round(event.loaded / event.total * 100)
            } else if (event.type === HttpEventType.Response){
                this.comment = '';
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: event.body.message
                });
                this.allforumList=[];
                this.myforumList=[];
                this.getAllForum();
                this.getMYForum();
            }
        }, error => {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message
            })
        });
    }

    allForumLike(postIdx,Liketype){
        let post = this.allforumList[postIdx];
         let likeData = (post.likes.length > 0) ? post.likes.find(el => { return el.user_id == this.user.id }) : post.likes;
        //  let type = (!likeData) ? 'Like' : 'Dislike';
        let type = (!likeData) ? Liketype : Liketype
        //let type = Liketype;
        const params = {forum_id: post.uuid, type: type};
        this.forumService.doLike(params).subscribe(res => {
            if (res.success) {
                
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: res.message
                });
                this.allforumList=[];
                this.myforumList=[];
                this.getAllForum();
                this.getMYForum();
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: res.message
                });
                this.allforumList=[];
                this.myforumList=[];
                this.getAllForum();
                this.getMYForum();
            }
        }, error => {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message
            })
        });
    }

    commentToComment(commentId,i){
            let post = this.allforumList[i];
            const formData = new FormData();
            formData.append('id','');
            formData.append('comment_id',(!commentId) ? '' : commentId);
            formData.append('forum_id',post.uuid);
            formData.append('comment',this.comment2);
            formData.append('attachment',this.attachment);
            this.forumService.doComment(formData).subscribe(event => {
                if (event.type === HttpEventType.UploadProgress){
                    this.uploadStatus = Math.round(event.loaded / event.total * 100)
                } else if (event.type === HttpEventType.Response){
                    this.comment2 = '';
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: event.body.message
                    });
                    this.allforumList=[];
                    this.myforumList=[];
                    this.getAllForum();
                    this.getMYForum();
                }
            }, error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error.message
                })
            });
    }
    
}
