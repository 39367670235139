import { Component, OnInit } from '@angular/core';
import { BlogService } from '../blog.service';
import {ActivatedRoute, Router} from "@angular/router";
import { imagePathHelper } from "../../../helpers/imagePathHelper";
import Swal from "sweetalert2";
import { Gallery, GalleryItem, ImageItem } from '@ngx-gallery/core';
import { AuthService } from 'app/services/auth.service';
import { environment } from 'enviornments/environment';
@Component({
    selector: 'app-blog_view',
    templateUrl: './blog_view.component.html',
    styleUrls: ['./blog_view.component.scss']
})

export class BlogViewComponent implements OnInit {
    env=environment;
    user : any;
    loading: boolean;
    errors: boolean;
    addcomment:any=true;
    visibility:any;
    logintocomment:any;
    blog: any;
    imgHelper=imagePathHelper;
    commentText: any;
    socialLinkPanel:boolean=false;
    attachmentHelper :any;
    thumbHelper :any;
    thumbHelperAllblog = imagePathHelper;
    imagedata:any=[];
    items: GalleryItem[];
    Linkhref: string = "";
    showMore = false;
    zoomimage:boolean=false;
    PostInfoImage:any=[];
    postIndex:any;
    constructor(private blogService: BlogService, private router: Router, private route: ActivatedRoute,public gallery: Gallery,private authService: AuthService) { }

    ngOnInit() {
        this.user = this.authService.getLoggedInUser();
        this.getBlogDetails(this.route.snapshot.params.id);
        
        
    }

    shareSociallink(uuid){
        let windooww= window.location.href.split('/solo');
        this.Linkhref='';
        this.Linkhref = windooww[0] + '/travelogues-detail/'+uuid;
        this.socialLinkPanel=true;
    }

    imageZomming(post,index){
        this.postIndex=index;
        this.PostInfoImage=[];
        this.zoomimage=true;
        this.PostInfoImage.push(post);
    }

    getBlogDetails(id) {
        let self=this;
        this.blogService.details(id).toPromise().then(res => {
            if (res.success){ 
                self.blog = res.data;
                for (let data of self.blog.attachments) {
                    let thumb = data.attachment.replace('public',this.env.imageURL);
                    //this.imagedata.push({srcUrl:thumb, previewUrl:thumb});
                    this.imagedata.push({image:thumb, thumbImage:thumb});
                }

                if(this.zoomimage){ 
                    this.PostInfoImage=[];
                    this.PostInfoImage.push(this.blog);
                }
            }
            this.items = self.imagedata.map(item => 
                new ImageItem({ src: item.srcUrl, thumb: item.previewUrl })
            );
                // Get a lightbox gallery ref
                const lightbox2Ref = this.gallery.ref('test-2');

                // Load items into the lightbox gallery ref
                lightbox2Ref.load(this.items);
            })
    }
 
    addBlog(){
        this.router.navigate(['solo/blog/add'])
    }
    doComment(event) {
        if(this.commentText==''){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please type some text in comment box'
            })
            return false;
        }else{
        if(this.commentText.trim()=='') return false;
        if (event=='Submit') {
            let param = {id:0,blog_id:this.route.snapshot.params.id,comment:this.commentText.trim()};
            this.blogService.comment(param).subscribe(res => {
                if (res.success) {
                    this.commentText = '';
                    this.getBlogDetails(this.route.snapshot.params.id);
                    Swal.fire('Comment', res.message, 'success');
                }
                else {
                    Swal.fire('Comment', res.message, 'error');
                }
            },error => {
                Swal.fire('Comment', error.message, 'error');
            });
        }
    }
    }

    

    doLike() {
        let param = {id:0,blog_id:this.route.snapshot.params.id,type:'Like'};
        this.blogService.like(param).subscribe(res => {
            if (res.success) {
                this.getBlogDetails(this.route.snapshot.params.id);
                Swal.fire('Like', res.message, 'success');
            }
            else {
                Swal.fire('Like', res.message, 'error');
            }
        },error => {
            Swal.fire('Like', error.message, 'error');
        });
    }

    switchToUserProfile(username){
        this.router.navigateByUrl(`userprofile/${username}`);
    }

    blogDetails(id){
        window.location.href='solo/blog/detail/'+id;
    }
}
