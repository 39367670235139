import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import {ProfileSetupService} from '../pages/profilesetup/profilesetup.service';
import Swal from 'sweetalert2';
@Component({
    selector: 'app-accountverification',
    templateUrl: './account_verification.component.html',
    styleUrls: ['./account_verification.component.scss']
})
export class AccountVerificationComponent implements OnInit {
    test : Date = new Date();
    focus;
    focus1;
    verificationID: any = this.route.snapshot.params.verificationID;
    user: any;
    UserName: any;
    startAutoSync: any = '';
    showEmailMsg = true;
    showMobileMsg = true;
    verificationUrl:boolean = ((this.router.url.split("/"))[2] == 'solo');
    constructor(private Service: ProfileSetupService, public http: HttpClient, private route: ActivatedRoute, private router: Router) { }

    ngOnInit() {
        this.verificationID = this.route.snapshot.params.verificationID;
        this.UserName = this.route.snapshot.params.userID;
        this.removeHeadFoot();
        this.emailVerify();
    }
    removeHeadFoot() {
        const navbar = document.getElementsByTagName('app-navbar')[0].children[0];
        navbar.classList.add('navbar-hidden');
        const footer = document.getElementsByTagName('app-footer')[0].children[0];
        footer.classList.add('navbar-hidden');
    }
    resendEmail() {
        this.Service.resendEmail({username : this.route.snapshot.params.verificationID}).subscribe( result => {
            if (result.success){
                Swal.fire('Email resent', result.message, 'success');
            }
        });
    }

    async emailMobileVerify(){
        setInterval(() => {
            this.Service.authVerification({user : this.route.snapshot.params.userID}).subscribe( result => {
                if (result.success){
                    if(result.data.status=='email_verified'){

                        if (this.showEmailMsg){
                            this.UserName = result.data.user.username;
                            localStorage.setItem('is_mobile_verified', '0');
                            localStorage.setItem('user', JSON.stringify(result.data.user));
                            localStorage.setItem('access_token', result.data.user.access_token);
                            Swal.fire({
                                icon: 'success',
                                title: 'Thank You',
                                html: 'Your email has been verified but mobile verification is pending please complete your verification to setup your profile.'
                            });
                            window.location.href =  '/solo/profile/setup/'+this.UserName;
                        }

                        this.showEmailMsg = false;
                    }
                    if(result.data.status=='verified'){
                        if (this.showMobileMsg){
                            this.UserName = result.data.user.username;
                            localStorage.setItem('is_mobile_verified', '1');
                            localStorage.setItem('user', JSON.stringify(result.data.user));
                            localStorage.setItem('access_token', result.data.user.access_token);
                            Swal.fire({
                                icon: 'success',
                                title: 'Thank You',
                                html: 'Your email and mobile has been verified. Please complete the setup of your profile.'
                            });
                            window.location.href =  '/solo/profile/setup/'+this.UserName;
                        }
                        this.showMobileMsg = false;
                    }
                }
            });
          }, 3000);
    }
    async emailVerify() {
        if(this.verificationUrl){
            await this.emailMobileVerify();
        }else{
            this.Service.emailVerification({code: this.verificationID}).subscribe( result => {
                if (result.success){
                    if(result.data.email_verified_at!=null && result.data.is_profile_setup=='No' && result.data.mobile_verified_at == null){
                        localStorage.setItem('user', JSON.stringify(result.data));
                        localStorage.setItem('access_token', result.data.access_token);
                        localStorage.setItem('is_mobile_verified', '0');
                        Swal.fire('Thank you', result.message, 'success').then((res) => {
                            window.location.href =  '/solo/profile/setup/'+result.data.username;
                            });
                    }else if(result.data.email_verified_at!=null && result.data.is_profile_setup=='No' && result.data.mobile_verified_at != null){
                        Swal.fire('Thank you', result.message, 'success').then((res) => {
                            localStorage.setItem('user', JSON.stringify(result.data));
                            localStorage.setItem('access_token', result.data.access_token);
                            localStorage.setItem('is_mobile_verified', '1');
                            window.location.href =  '/solo/profile/setup/'+result.data.username;
                        });
                    }else if(result.data.email_verified_at!=null && result.data.is_profile_setup=='Yes' && result.data.mobile_verified_at != null){
                        localStorage.setItem('user', JSON.stringify(result.data));
                        localStorage.setItem('access_token', result.data.access_token);
                        localStorage.setItem('is_mobile_verified', '1');
                        Swal.fire('Thank you', result.message, 'success').then((res) => {
                            window.location.href =  '/solo/profile/edit';
                            });
                    }else{
                       // Swal.fire('Error occurred', result.message, 'error');
                    }
                }
            });
        }
    }
}
