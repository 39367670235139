import { Injectable } from '@angular/core';
import { BaseService } from '../../_services/base.service';

@Injectable()
export class ForumService {

    constructor(private baseService: BaseService) { }

    getMyForumList(){
        return this.baseService.get(`forum/list`);
    }
    
    getAllForumList(){
        return this.baseService.get(`forums`);
    }
    
    addPost(params) {
        return this.baseService.post(`forum/addUpdate`, params);
    }

    detailsPatch(uuid) {
        return this.baseService.getRequest(`forum/edit/`+uuid);
    }

    delete(uuid) {
        return this.baseService.delete(`forum/delete/`+uuid);
    }

    doComment(data){
        return this.baseService.postFile(`forum/comment`, data);
    }

    doLike(data){
        return this.baseService.post(`forum/like`, data);
    }

    MYdoComment(data){
        return this.baseService.postFile(`forum/comment`, data);
    }

}
