import {fromEvent as observableFromEvent, Observable} from 'rxjs';
import { Component, OnInit, Inject, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';
import { DOCUMENT } from '@angular/common';
import { LocationStrategy, PlatformLocation, Location } from '@angular/common';
import { AppNavbarComponent } from './shared/navbar/app-navbar.component';
import { AuthService } from "./services/auth.service";
import { BaseService } from './_services/base.service';
import {MessageService} from 'primeng/api';
import {CookiesService} from "./shared/services/cookies.services";
import {EncryptionService} from "./shared/services/encryption.services";
import {Globals} from "./globals";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    private _router: Subscription;
    @ViewChild(AppNavbarComponent) navbar: AppNavbarComponent;
    onlineStatus: Observable<Event>;
    offlineStatus: Observable<Event>;
    subscriptions: Subscription[] = [];  
    loginTimer:any;
    title = 'app';
    loader: any;
    constructor(private messageService: MessageService,private authService: AuthService,private baseService: BaseService, private renderer : Renderer2, @Inject(DOCUMENT,) private document: any, private element : ElementRef, public location: Location, private router: Router, private cookieService: CookiesService,private globals: Globals,private encryptionServices: EncryptionService) {}
    ngOnInit() {
        var navbar : HTMLElement = this.element.nativeElement.children[0].children[0];
        this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
            if (window.outerWidth > 991) {
                window.document.children[0].scrollTop = 0;
            }else{
                window.document.activeElement.scrollTop = 0;
            }
            this.navbar.sidebarClose();
        });
        this.renderer.listen('window', 'scroll', (event) => {
            const number = window.scrollY;
            if (number > 150 || window.pageYOffset > 150) {
                // add logic
                //navbar.classList.remove('navbar-transparent');
            } else {
                // remove logic
               // navbar.classList.add('navbar-transparent');
            }
        });
        var ua = window.navigator.userAgent;
        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            var version = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }
        if (version) {
            var body = document.getElementsByTagName('body')[0];
            body.classList.add('ie-background');

        }

        this.checkNetworkConnection();
        this.validateSession();

    }
    removeFooter() {
        var title = this.location.prepareExternalUrl(this.location.path());
        title = title.slice( 1 );
        return !(title === 'signup' || title === 'nucleoicons');
    }

    logout(): void {
        this.authService.logout()
            .subscribe(() => {
                localStorage.removeItem('currentUserID');
                this.router.navigate(['/login']);
            });
    }

    checkNetworkConnection(){

        this.onlineStatus = observableFromEvent(window, 'online');
        this.offlineStatus = observableFromEvent(window, 'offline');
    
        this.subscriptions.push(this.onlineStatus.subscribe(e => {
          this.messageService.add({severity:'success', summary: 'Network Status',detail:'Great! You are now connected to the internet'})
        }));
    
        this.subscriptions.push(this.offlineStatus.subscribe(e => {
          this.messageService.add({severity:'error', summary: 'Network Status',detail:'Connection lost! You are not connected to the internet'})
        }));
    
      }

    validateSession(){
        try{
            var self = this;
            if(this.cookieService.get(this.globals.tw_cookie)){
                if(this.authService.getAppToken()){
                    self.loginTimer = setInterval(function() {

                        self.baseService.validate(`user/validate`).subscribe((res)=>{
                            if(!res.status && res.message != 'connected'){
                                self.authService.activeSessions(0,'');
                            }
                        })
                    }, self.globals.sessionValidateTime);
                }
            }
        }catch(error){
            window.location.href = "/";
        }
    }

      
}
