import { Injectable } from '@angular/core';
import { BaseService } from '../../../../_services/base.service';

@Injectable()
export class PostGroupProfileService {

    constructor(private baseService: BaseService) { }

    getAllPosts(){
        return this.baseService.get(`user/posts/list`);
    }

    postDetails(data){
        return this.baseService.post(`user/posts/details`, data);
    }

    doLike(data){
        return this.baseService.post(`user/posts/like`, data);
    }

    doComment(data){
        return this.baseService.postFile(`user/posts/comment`, data);
    }

    getFollowersList() {
        return this.baseService.getRequest(`user/follow/list`).toPromise();
    }

}
