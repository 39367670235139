import {Component, OnInit, Renderer2} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {SignUpService} from './signup.service';
import {FormBuilder, Validators, FormGroup} from '@angular/forms';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {SelectItem} from 'primeng/api';
import { ValidationsService } from '../services/validations.service';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
    test: Date = new Date();
    dateValue: Date;
    date1: Date;
    focus;
    focus1;
    date: { year: number, month: number };
    model: NgbDateStruct;
    signUpForm: FormGroup;
    termsChecked = false;
    countryList: any = [];
    phoneCodeVal: any;
    selectedCountry: any = [];
    contentLoader = false;
    emailPattern = /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,9})$/;
    signupUrl: boolean = ((this.router.url.split("/"))[1] == 'signup');

    constructor(private fb: FormBuilder, private auth: SignUpService, public http: HttpClient, private router: Router, private customValidate: ValidationsService) {
    }

    isWeekend(date: NgbDateStruct) {
        const d = new Date(date.year, date.month - 1, date.day);
        return d.getDay() === 0 || d.getDay() === 6;
    }

    isDisabled(date: NgbDateStruct, current: { month: number }) {
        return date.month !== current.month;
    }

    getDOB(date: NgbDateStruct, current: { month: number }) {
        return date.year + '-' + date.month + '-' + date.day;
    }

    ngOnInit() {
        this.signUpForm = this.fb.group({
            name: ['', [Validators.required,Validators.minLength(3),Validators.maxLength(50)]],
            email: ['', [Validators.required,Validators.minLength(5),Validators.maxLength(50),Validators.pattern(this.emailPattern)]],
            mobile: ['', [Validators.required,Validators.minLength(10),Validators.maxLength(10)]],
            dob: ['', [Validators.required]],
            countryCode:["",[Validators.required]],
            password: ['', [Validators.required, Validators.minLength(8),
            Validators.pattern('(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[$@$#!%*?&])[A-Za-z0-9\d$@$#!%*?&].{8,}')]],
            reffered_by: [''],
            terms: ['', [Validators.required]],
        });
        this.dobDate();
        this.getCountries();
        // const navbar = document.getElementsByTagName('app-navbar')[0].children[0];
        // navbar.classList.add('navbar-hidden');
        if (this.signupUrl) {
            const footer1 = document.getElementsByTagName('app-footer')[0].children[0];
            footer1.classList.add('remove-footer-mobile');
            const footer2 = document.getElementsByTagName('footer')[0].children[0];
            footer2.classList.add('remove-footer-mobile');
        }

    }


    get name() {
        return this.signUpForm.controls.name;
    }

    get email() {
        return this.signUpForm.controls.email;
    }

    get mobile() {
        return this.signUpForm.controls.mobile;
    }

    get dob() {
        return this.signUpForm.controls.dob;
    }

    get password() {
        return this.signUpForm.controls.password;
    }
    get reffered_by() {
        return this.signUpForm.controls.reffered_by;
    }

    get countryCode() {
        return this.signUpForm.controls.countryCode
    }

    get terms() {
        return this.signUpForm.controls.terms;
    }

    dobDate() {

        var now = new Date();
        var isoDate = new Date(now).toISOString();
        let newDate = (isoDate.split('T')[0])
        let endDateTime = newDate;
        let finaldate = this.customValidate.AddMinusInDate(endDateTime, 6570, 'minus');
        this.date1 = new Date(finaldate);

    }

    getCode(value) {
        this.phoneCodeVal = value;
    }

    signUp(formData) {
        let allData = formData;
        if (!this.phoneCodeVal) {
            this.phoneCodeVal = '+' + 91
        }
        let mobile = this.phoneCodeVal + ' ' + formData.mobile;
        Swal.fire({
            icon: 'warning',
            title: 'Re-Verify',
            showCancelButton: true,
            html: 'Dear' + ' ' + formData.name + '!' + '<br>' + 'Please confirm the email and phone number entered by you' + ' <br>' + '<b>Email : <b>' + formData.email + '<br>' + '<b>Mobile : <b>' + mobile
        }).then((result) => {
            if (result.isConfirmed) {
                this.registeration(allData);
                // Swal.fire('Saved!', '', 'success')
            }
        });
    }

    registeration(formData) {
        this.contentLoader = true;
        formData.dob = this.customValidate.formatDate(formData.dob);
        if (!this.phoneCodeVal) {
            this.phoneCodeVal = '+' + 91
        }
        formData.mobile = this.phoneCodeVal + ' ' + formData.mobile;
        this.auth.signUp(formData).subscribe(result => {
            if (result.success) {
                sessionStorage.setItem('mobile', formData.mobile);
                sessionStorage.setItem('email', formData.email);
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    html: result.message
                }).then(function () {
                        window.location.href = '/otp-verification/' + result.data.user;
                    }
                );
                this.signUpForm.reset();
                this.phoneCodeVal = '';
                this.getCountries();
                this.contentLoader = false;
            } else {
                //this.signUpForm.reset();
                this.phoneCodeVal = '';
                this.getCountries();
                Swal.fire('Error occurred', result.message, 'error');
                this.contentLoader = false;
            }
        }, err => {
            this.phoneCodeVal = '';
            this.getCountries();
            this.contentLoader = false;
        });
    }

    agreementEvent(event) {
        this.termsChecked = event;
    }

    getCountries() {
        this.selectedCountry = [];
        this.auth.getCountryJSON().subscribe(data => {
            this.countryList = [];
            for (let i = 0; i < data.countries.length; i++) {
                this.countryList.push({label: data.countries[i].code, value: data.countries[i].code});
            }
            this.selectedCountry = {label: data.countries[102].code, value: data.countries[102].code};
        });

    }

    submitForm() {
        this.registeration(this.signUpForm.value);
    }
}
