import { Injectable } from '@angular/core';
import { BaseService } from '../../../_services/base.service';
import { GroupSidebarService } from '../sidebar/sidebar.service';

@Injectable()
export class GroupProfileService {
    groupId:any='';
    groupName:any='';
    constructor(private baseService:BaseService, private sidebarService:GroupSidebarService) { 
        this.groupId = this.sidebarService.groupData.uuid;
        this.groupName = this.sidebarService.groupData.unique_name;
    }
    

    profile(){
        return this.baseService.get(`group/${this.groupName}`);
    }
    
    uploadProfileImage(image) {
        return this.baseService.postFile(`group/update/profile-image/${this.groupId}`, image);
    }



    uploadProfileImageBase64(image) {
        return this.baseService.post(`group/update/profile-image/${this.groupId}`, image);
    }
    
    uploadProfileCoverImage(image) {
        return this.baseService.postFile(`group/update/profile-cover-image/${this.groupId}`, image);
    }
    
    uploadProfileCoverImageBase64(image) {
        return this.baseService.post(`group/update/profile-cover-image/${this.groupId}`, image);
    }

    getFollowersList() {
        return this.baseService.getRequest(`group/follow/list/${this.groupId}`).toPromise();
    }

     // Update the group ID when the group data changes
     updateGroupId() {
        this.groupId = this.sidebarService.groupData.uuid;
    }
    


}
