import { Component, OnInit } from '@angular/core';
import { GroupCreationService } from './create.service';
import {SelectItem} from 'primeng/api';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import Swal from "sweetalert2";
import {HttpEventType} from "@angular/common/http";
@Component({
    selector: 'app-groupcreation',
    templateUrl: './create.component.html',
    styleUrls: ['./create.component.scss']
})


export class GroupCreationComponent implements OnInit {
    CreateGroupForm: FormGroup;
    user: any = {};
    selectedUsers: SelectItem[];
    referralList:any;
    loading: boolean;
    errors: boolean;
    display1:boolean;
    usersList: SelectItem[];
    Users:any=[];    
    image: File = null;
    Coverimage: File = null;
    uploadStatus: number;
    imageURL:any;
    CoverimageURL:any;
    constructor(private GroupCreationService: GroupCreationService,private fb: FormBuilder,) {}
     
    

    ngOnInit() {
      this.getFollowersList();   
      this.CreateGroupForm = this.fb.group({
        id: [0],
        name: ['', [Validators.required]],
        about: ['', [Validators.required]],
        board_members: ['', [Validators.required]],
    });
    }

    get id() {
        return this.CreateGroupForm.controls.id
    }

    get name() {
        return this.CreateGroupForm.controls.name
    }

    get about() {
        return this.CreateGroupForm.controls.about
    }

    get board_members() {
        return this.CreateGroupForm.controls.board_members
    }

    handleImageInput(e) {
        if (e.target.files && e.target.files.length) {
            this.image = <File>e.target.files[0];
        }    
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]); 
        reader.onload = (_event) => { 
        this.imageURL = reader.result; 
        }
    }
    

    handleCoverImageInput(e) {
        if (e.target.files && e.target.files.length) {
            this.Coverimage = <File>e.target.files[0];
        }
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]); 
        reader.onload = (_event) => { 
        this.CoverimageURL = reader.result; 
        }
    }

    CreateGroup(data) {
        const params = new FormData();
        params.append('id', data.id);
        params.append('name', data.name);
        params.append('about', data.about);
        params.append('board_members', data.board_members);
        params.append('profile_image', this.image);
        params.append('cover_image', this.Coverimage);
        this.GroupCreationService.addUpdate(params).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress){
                this.uploadStatus = Math.round(event.loaded / event.total * 100)
            } else if (event.type === HttpEventType.Response){
                this.resetPostForm();
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: event.body.message
                })
            }
        }, error => {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message
            })
        });
    }


   getFollowersList() {
        this.usersList=[];
        this.GroupCreationService.getFollowersList().then((res) => {
            if (res) {
                this.Users = res.data;
            }
            const userlist = this.Users.filter(el => {return el.type=='traveler'});
            for(let list of userlist){
                this.usersList.push({label:list.name,value:list.id});
            }

            
        }).catch((error) => {});
    }

    resetPostForm() {
        this.CreateGroupForm.reset();
        this.image = null;
        this.Coverimage = null;
        this.imageURL=null;
        this.CoverimageURL=null;
    }
    
}
    

    

    
    
    

