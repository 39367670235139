import { Component, OnInit } from '@angular/core';
import { GroupMembersService } from './members.service';

@Component({
    selector: 'app-group_members',
    templateUrl: './members.component.html',
    styleUrls: ['./members.component.scss']
})

export class GroupMembersComponent implements OnInit {
    user: any = {};
    referralList:any;
    loading: boolean;
    errors: boolean;
    display1:boolean;
    constructor(private referralService: GroupMembersService) { }

    ngOnInit() {
        
        
    }

    

    
    
    showReadMore() {
        this.display1 = true;
        
    }
}
